
/**
 * Module dependencies.
 */

import { Cell, Row, RowGroup, Table } from 'src/components/core/table';
import { Credit } from 'src/types/credits';
import { Type, color, units } from '@untile/react-components';
import { formatDate } from 'src/core/utils/formatter';
import { useTranslation } from 'next-i18next';
import Button from 'src/components/core/buttons/button';
import Modal from 'src/components/core/modals/modal';
import styled from 'styled-components';

/**
 * Table headers.
 */

const tableHeaders = [
  'creditsNumber',
  'description',
  'expireDate'
];

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  data: Credit[],
  isOpen?: boolean,
  onClose: () => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

/**
 * `StyledTable` styled component.
 */

const StyledTable = styled(Table)`
  margin-bottom: ${units(3)};
`;

/**
 * `HeadRowGroup` styled component.
 */

const HeadRowGroup = styled(RowGroup)`
  border-bottom: 1px solid ${color('grey50')};
`;

/**
 * `BodyRow` styled component.
 */

const BodyRow = styled(Row)`
  border-bottom: 1px solid ${color('grey50')};
`;

/**
 * `HeadCell` styled component.
 */

const HeadCell = styled(Cell)`
  color: ${color('grey600')};
`;

/**
 * `ExpireCreditsModal` component.
 */

const ExpireCreditsModal = (props: Props) => {
  const { className, data, isOpen, onClose } = props;
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
    >
      <Wrapper className={className}>
        <Type.H1 marginBottom={units(1)}>
          {t('common:credits.expireCreditsModal.title')}
        </Type.H1>

        <StyledTable>
          <HeadRowGroup type={'head'}>
            <Row>
              {tableHeaders.map(name => (
                <HeadCell
                  key={name}
                  name={name}
                >
                  {t(`common:credits.expireCreditsModal.labels.${name}`)}
                </HeadCell>
              ))}
            </Row>
          </HeadRowGroup>

          <RowGroup type={'body'}>
            {data.map(({ availableCredits, expiryAt, freeAt }, index) => (
              <BodyRow
                key={index}
                rowIndex={index + 1}
              >
                <Cell>
                  {availableCredits}
                </Cell>

                <Cell>
                  {freeAt ? t(
                    'common:credits.expireCreditsModal.packsDescription.offered'
                  ) : t(
                    'common:credits.expireCreditsModal.packsDescription.bought'
                  )}
                </Cell>

                <Cell>
                  {expiryAt ? formatDate(new Date(expiryAt), 'dd/MM/yyyy - HH:mm') : '-'}
                </Cell>
              </BodyRow>
            ))}
          </RowGroup>
        </StyledTable>

        <Button
          colorTheme={'secondary'}
          onClick={onClose}
        >
          {t('common:actions.close')}
        </Button>
      </Wrapper>
    </Modal>
  );
};

/**
 * Export `ExpireCreditsModal` component.
 */

export default ExpireCreditsModal;
