
/**
 * Module dependencies.
 */

import { animations } from './animations';
import { colors } from './colors';
import { dimensions } from './dimensions';
import { typography } from './type';
import keyframes from './keyframes';

/**
 * Export `theme`.
 */

export const theme = {
  animations,
  colors,
  dimensions,
  grid: {
    gutter: 56,
    mobileGutter: 24
  },
  keyframes,
  typography,
  zIndex: {
    dropdown: 95,
    lateralMenu: 97,
    menu: 96,
    modal: 98,
    pageTransition: 90,
    snackbar: 99,
    tooltip: 99
  }
};
