/**
 * Module dependencies.
 */

import { ReactNode, useState } from 'react';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import Svg from 'src/components/core/svg';
import Transition, {
  TransitionStatus
} from 'react-transition-group/Transition';
import infoIcon from 'src/assets/svg/info.svg';
import styled, { css } from 'styled-components';

/**
 * Export `Placement` type.
 */

export type Placement = 'top' | 'bottom';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  className?: string;
  hideIcon?: boolean;
  placement?: Placement;
  tip: ReactNode;
  width?: string | ((props: any) => string);
};

/**
 * Common dimensions.
 */

const caretHeight = units(1);
const caretWidth = units(2);
const offsetX = units(6);
const offsetY = units(1);

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;
  width: max-content;
`;

/**
 * `ElementWrapper` styled component.
 */

const ElementWrapper = styled.div`
  display: inline-block;
`;

/**
 * `PopUp` styled component.
 */

const PopUp = styled.div<
  Pick<Props, 'placement' | 'width'> & {
    hasIcon: boolean;
    transitionState: TransitionStatus;
  }
>`
  align-items: center;
  background-color: ${color.transparentize('black', 0.85)};
  border-radius: 10px;
  color: ${color('white')};
  display: grid;
  font-size: 12px;
  line-height: 16px;
  padding: ${units(3)};
  position: absolute;
  transition: opacity ${theme('animations.defaultTransition')};
  width: ${prop('width', units(50))};
  z-index: ${theme('zIndex.tooltip')};

  ${ifProp(
    'hasIcon',
    css`
      grid-column-gap: ${units(2)};
      grid-template-columns: max-content 1fr;
    `
  )}

  &::after {
    content: '';
    height: 0;
    position: absolute;
    width: 0;
  }

  ${switchProp('transitionState', {
    entered: css`
      opacity: 1;
    `,
    entering: css`
      opacity: 1;
    `,
    exited: css`
      opacity: 0;
    `,
    exiting: css`
      opacity: 0;
    `
  })}

  ${switchProp('placement', {
    bottom: css`
      left: 50%;
      margin-top: ${offsetY};
      top: 100%;
      transform: translateX(-${offsetX});

      &::after {
        border-bottom: ${caretHeight} solid
          ${color.transparentize('black', 0.85)};
        border-left: calc(${caretWidth} / 2) solid transparent;
        border-right: calc(${caretWidth} / 2) solid transparent;
        left: ${offsetX};
        top: -${caretHeight};
        transform: translateX(-50%);
      }
    `,
    top: css`
      bottom: 100%;
      left: 50%;
      margin-bottom: ${offsetY};
      transform: translateX(-${offsetX});

      &::after {
        border-left: ${units(1)} solid transparent;
        border-right: ${units(1)} solid transparent;
        border-top: ${units(1)} solid ${color.transparentize('black', 0.85)};
        bottom: ${units(-1)};
        left: ${offsetX};
        transform: translateX(-50%);
      }
    `
  })}
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg)`
  color: ${color('white')};
`;

/**
 * `Tooltip` component.
 */

const Tooltip = (props: Props) => {
  const { children, className, hideIcon, placement, tip, width } = props;
  const [visible, setVisible] = useState<boolean>(false);

  return (
    <Wrapper onMouseLeave={() => setVisible(false)}>
      <ElementWrapper
        className={className}
        onMouseOver={() => setVisible(true)}
      >
        {children}
      </ElementWrapper>

      <Transition in={visible} mountOnEnter timeout={250} unmountOnExit>
        {transitionState => (
          <PopUp
            hasIcon={!hideIcon}
            placement={placement}
            transitionState={transitionState}
            width={width}
          >
            {!hideIcon && <Icon icon={infoIcon} size={units(3)} />}

            {tip}
          </PopUp>
        )}
      </Transition>
    </Wrapper>
  );
};

/**
 * Default props.
 */

Tooltip.defaultProps = {
  placement: 'bottom'
};

/**
 * Export `Tooltip` component.
 */

export default Tooltip;
