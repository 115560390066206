
/**
 * Module dependencies.
 */

import { ifNotProp, ifProp, prop, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  active: boolean,
  className?: string,
  relative?: boolean,
  size?: number,
  stroke?: number
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.span<Pick<Props, 'active' | 'relative'>>`
  display: inline-block;
  opacity: ${ifProp('active', 1, 0)};
  pointer-events: none;
  transform: scale(0.5);
  transition: ${theme('animations.defaultTransition')};
  transition-property: opacity, transform;

  ${ifNotProp('relative', css`
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) scale(0.5);
    z-index: 1;
  `)}

  ${ifProp(['active', 'relative'], css`
    transform: scale(1);
  `)}

  ${({ active, relative }) => active && !relative && css`
    transform: translate(-50%, -50%) scale(1);
  `}
`;

/**
 * `Loader` styled component.
 */

const Loader = styled.span<Pick<Props, 'size' | 'stroke'>>`
  animation: ${theme('keyframes.spin')} 0.75s linear infinite;
  border-left-color: transparent;
  border-radius: ${prop('size')}px;
  border-style: solid;
  border-width: ${prop('stroke')}px;
  display: block;
  height: ${prop('size')}px;
  width: ${prop('size')}px;
`;

/**
 * `Loading` component.
 */

const Loading = (props: Props) => {
  const { active, className, relative, ...rest } = props;

  return (
    <Wrapper
      active={active}
      className={className}
      relative={relative}
    >
      <Loader {...rest} />
    </Wrapper>
  );
};

/**
 * Default props.
 */

Loading.defaultProps = {
  size: 24,
  stroke: 2
};

/**
 * Export `Loading` component.
 */

export default Loading;
