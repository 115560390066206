
/**
 * Module dependencies.
 */

import { Svg as SvgComponent } from '@untile/react-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  color?: string,
  icon: string,
  size: string | unknown
};

/**
 * `Svg` component.
 */

const Svg = ({ icon, ...rest }: Props) => (
  <SvgComponent
    {...rest}
    // @ts-ignore
    icon={icon}
  />
);

/**
 * Export `Svg` component.
 */

export default Svg;
