
/**
 * Module dependencies.
 */

import { Fragment } from 'react';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, prop, theme } from 'styled-tools';
import { menu, shortcuts } from 'src/core/menu';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Dropright from './dropright';
import IconButton from 'src/components/core/buttons/icon-button';
import LogoLink from 'src/components/menu/logo-link';
import Submenu from './submenu';
import SubmenuProfile from 'src/components/menu/submenu-profile';
import accountIcon from 'src/assets/svg/account.svg';
import isEmpty from 'lodash/isEmpty';
import menuIcon from 'src/assets/svg/menu.svg';
import styled, { css } from 'styled-components';

/**
 * `OuterWrapper` styled component.
 */

const OuterWrapper = styled.div`
  height: 100vh;
  left: 0;
  padding: ${units(4)} ${theme('dimensions.menuPaddingHorizontal')}px;
  position: fixed;
  top: 0;
  z-index: ${theme('zIndex.lateralMenu')};
`;

/**
 * `InnerWrapper` styled component.
 */

const InnerWrapper = styled.div`
  align-items: center;
  background: ${color('white')};
  border-radius: ${units(5)};
  box-shadow: ${units(-0.5)} ${units(1)} ${units(4)} ${units(0.5)} ${color.transparentize('black', 0.08)};
  display: grid;
  grid-row-gap: ${units(3)};
  grid-template-rows: max-content 1fr;
  height: 100%;
  justify-items: center;
  padding: ${units(2)} 0;
  width: ${theme('dimensions.menuWidth')}px;
`;

/**
 * `Items` styled component.
 */

const Items = styled.div<{ total: number }>`
  align-items: flex-end;
  display: grid;
  grid-row-gap: ${units(3)};
  grid-template-rows: repeat(calc(${prop('total')}), max-content) 1fr;
  height: 100%;
  position: relative;
`;

/**
 * `StyledIconButton` styled component.
 */

const StyledIconButton = styled(IconButton)`
  ${ifProp('isActive', css`
    & > span:first-child {
      background-color: ${color('primary')};
      transform: translate(-50%, -50%) scale(1);
    }
  `)}
`;

/**
 * `SubmenuProfileWrapper` styled component.
 */

const SubmenuProfileWrapper = styled.div`
  background-color: ${color('white')};
  border-radius: ${units(1)};
  box-shadow: 0 ${units(1)} ${units(4)} ${color.transparentize('black', 0.1)};
  left: calc(${theme('dimensions.menuWidth')}px);
  position: relative;

  &::before {
    border-bottom: ${units(1.25)} solid transparent;
    border-right: ${units(1.5)} solid ${color('white')};
    border-top: ${units(1.25)} solid transparent;
    content: '';
    height: 0;
    left: -${units(1.25)};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 0;
  }
`;

/**
 * `Menu` component.
 */

const Menu = () => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <OuterWrapper>
      <InnerWrapper>
        <LogoLink />

        <Items total={shortcuts.length + 1}>
          <Dropright
            renderButton={buttonProps => (
              <StyledIconButton
                {...buttonProps}
                icon={menuIcon}
                iconSize={units(3)}
              />
            )}
            renderChildren={({ onClose }) => (
              <Submenu
                entries={menu}
                onClose={onClose}
                title={t('common:menu.label')}
              />
            )}
          />

          {shortcuts.map(
            ({ icon, label, submenu, to }, index) => (
              <Fragment key={index}>
                {isEmpty(submenu) ? (
                  <StyledIconButton
                    href={to}
                    icon={icon}
                    iconSize={units(3)}
                    isActive={router.asPath.startsWith(to)}
                  />
                ) : (
                  <Dropright
                    renderButton={buttonProps => (
                      <StyledIconButton
                        {...buttonProps}
                        icon={icon}
                        iconSize={units(3)}
                      />
                    )}
                    renderChildren={({ onClose }) => (
                      <Submenu
                        entries={submenu}
                        onClose={onClose}
                        title={t(label)}
                      />
                    )}
                  />
                )}
              </Fragment>
            ))}

          <Dropright
            renderButton={buttonProps => (
              <StyledIconButton
                {...buttonProps}
                icon={accountIcon}
                iconSize={units(3)}
              />
            )}
            renderChildren={({ onClose }) => (
              <SubmenuProfileWrapper>
                <SubmenuProfile onClose={onClose} />
              </SubmenuProfileWrapper>
            )}
          />
        </Items>
      </InnerWrapper>
    </OuterWrapper>
  );
};

/**
 * Export `Menu` component.
 */

export default Menu;
