
/**
 * Module dependencies.
 */

import { Entry } from 'src/components/menu/entries';
import { MenuProfileEntry } from 'src/types/menu';
import { color, units } from '@untile/react-components/dist/styles';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Svg from 'src/components/core/svg';
import goToIcon from 'src/assets/svg/go-to.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  entries: Array<MenuProfileEntry>
}

/**
 * `MenuList` styled component.
 */

const MenuList = styled.ul`
  background-color: ${color('white')};
  border-radius: ${units(2.5)};
  box-shadow: 0 ${units(1)} ${units(4)} ${color.transparentize('black', 0.1)};
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: ${units(1)};
  min-height: ${units(22)};
  min-width: ${units(32)};
  padding: ${units(4)} ${units(2)};
  width: min-content;
`;

/**
 * `StyledEntry` styled component.
 */

const StyledEntry = styled(Entry)`
  align-items: center;
  display: flex;
  padding: ${units(0.5)} ${units(3)};
  width: 100%;
`;

/**
 * `GoToSvg` styled component.
 */

const GoToSvg = styled(Svg).attrs({
  icon: goToIcon,
  size: '18px'
})`
  color: ${color('grey600')};
  margin-left: 11px;
`;

/**
 * `MenuProfile` component.
 */

const MenuProfile = ({ className, entries }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <MenuList className={className}>
      {entries.map(({ isExternal, label, ...rest }, index: number) => (
        <li key={index}>
          <StyledEntry
            {...rest}
            isActive={router.pathname === rest.to}
          >
            {t(label)}

            {isExternal && <GoToSvg />}
          </StyledEntry>
        </li>
      ))}
    </MenuList>
  );
};

/**
 * Export `MenuProfile` component.
 */

export default MenuProfile;
