
/**
 * Export `regexes`.
 */

export const regexes = {
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  interpolateUrl: /:([\s\S]+?)\b/g,
  password: /^(?=.*\d)(?=.*[!_@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
  phoneWithExtension: /^(00|\+)[1-9]/,
  phoneWithoutExtension: /^(?!00|\+)\d/,
  routeQuery: /[^?&]\[([^\]]+)\]/g
};
