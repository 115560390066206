
/**
 * Default transition.
 */

const defaultTransition = '0.5s ease';

/**
 * Fast transition.
 */

const fastTransition = '0.2s ease';

/**
 * Export `animations`.
 */

export const animations = {
  defaultTransition,
  fastTransition
};
