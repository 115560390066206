/**
 * Module dependencies.
 */

import { Credit } from 'src/types/credits';
import { Params, getCredits } from './get-credits';
import {
  QueryObserverBaseResult,
  QueryObserverOptions,
  useQuery
} from 'react-query';

import { useMemo } from 'react';
import { useSession } from 'src/context/session/context';

/**
 * `Options` type.
 */

type Options = QueryObserverOptions<Credit[], any, any, any> & {
  params?: Params;
};

/**
 * `Result` type.
 */

type Result = QueryObserverBaseResult<Credit[] | null, unknown>;

/**
 * Action type.
 */

const actionType = 'get-credits';

/**
 * `useCredits` hook.
 */

function useCredits(options?: Options): Result {
  const { params, ...rest } = options ?? {};
  const { token } = useSession();
  const variables = useMemo(
    () => ({
      params,
      token
    }),
    [params, token]
  );

  const result = useQuery(
    [actionType, params],
    () => {
      return getCredits(variables);
    },
    { ...rest }
  );

  return result;
}

/**
 * Export `useCredits` hook.
 */

export default useCredits;
