
/**
 * Module dependencies.
 */

import { ElementType, forwardRef } from 'react';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, theme } from 'styled-tools';
import { isExternalUrl } from '@untile/react-components/dist/utils';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  as?: ElementType,
  className?: string,
  disabled?: boolean,
  href?: string,
  icon: string,
  iconSize: string | ((props: any) => string),
  onClick?: () => void
};

/**
 * `Button` styled component.
 */

const Button = styled.button.attrs<Props>(({ as, href, type }) => {
  const isExternal = isExternalUrl(href);
  const element = as || href && !isExternal && RouterLink || href && isExternal && 'a' || 'button';

  return {
    as: element,
    type: type || (element === 'button' ? 'button' : null)
  };
})<{ size: string }>`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  background-color: ${color('yellow200')};
  border: none;
  border-radius: ${units(0.5)};
  color: ${color('grey900')};
  cursor: pointer;
  line-height: 0;
  outline: none;
  padding: ${units(0.5)};
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, opacity;

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.6;
    pointer-events: none;
  `)}

  &:hover {
    background-color: ${color('yellow400')};
  }
`;

/**
 * `IconButtonSquared` component.
 */

const IconButtonSquared = forwardRef<any, Props>((props: Props, ref: any) => {
  const { icon, iconSize, ...rest } = props;

  return (
    <Button
      {...rest}
      ref={ref}
      size={iconSize}
    >
      <Svg
        icon={icon}
        size={iconSize}
      />
    </Button>
  );
});

/**
 * `IconButtonSquared` display name.
 */

IconButtonSquared.displayName = 'IconButtonSquared';

/**
 * Export `IconButtonSquared` component.
 */

export default IconButtonSquared;
