/**
 * Module dependencies.
 */

import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { SessionContext } from './context';
import { Token } from 'src/types/api';
import { UserSession } from 'src/types/session';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { sendCrispEvent, useCrispUserData } from 'src/hooks/use-crisp';
import { useRouter } from 'next/router';
import { useSnackbar } from 'src/context/snackbar/context';
import { useTranslation } from 'next-i18next';
import useMe from 'src/api/me/use-me';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `SessionProvider` provider.
 */

const SessionProvider = ({ children }: Props) => {
  const cookies = parseCookies();
  const cookieToken = cookies?.token;
  const isCalledRef = useRef(false);
  const router = useRouter();
  const { showMessage } = useSnackbar();
  const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [token, setToken] = useState<Token>(cookieToken);
  const [user, setUser] = useState<UserSession>(null);
  const { isLoading, isSuccess, refetch, remove } = useMe({
    enabled: !!token,
    onSuccess: data => {
      setUser(data);
    },
    token
  });

  const handleUpdateToken = useCallback((token: Token) => {
    setToken(token);
    setCookie(null, 'token', token, {
      maxAge: 3650 * 24 * 60 * 60,
      path: '/'
    });
  }, []);

  const handleClearToken = useCallback(() => {
    setToken(null);
    destroyCookie(null, 'token', { path: '/' });
  }, []);

  useEffect(() => {
    if (user && token && !isAuthenticated) {
      setIsAuthenticated(true);
      sendCrispEvent('signIn');
    }

    if ((!token && user) || !token) {
      remove();
      setUser(null);
      setIsAuthenticated(false);
    }
  }, [isAuthenticated, remove, token, user]);

  useEffect(() => {
    if (router?.query?.session === 'expired' && !isCalledRef.current) {
      isCalledRef.current = true;

      router
        .replace(router?.pathname, undefined, { shallow: true })
        .finally(() => {
          showMessage(t('common:session.expired'), {
            appearance: 'warning'
          });

          handleClearToken();
        });
    }

    const handleRouteChangeComplete = () => {
      if (isCalledRef.current === true) {
        isCalledRef.current = false;
      }
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [handleClearToken, router, showMessage, t]);

  // Send user data to Crisp chat.
  useCrispUserData({ isAuthenticated, user });

  return (
    <SessionContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        isSuccess,
        onClearToken: handleClearToken,
        onUpdateToken: handleUpdateToken,
        onUpdateUser: refetch,
        token,
        user
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

/**
 * Export `SessionProvider` provider.
 */

export default SessionProvider;
