
/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, theme } from 'styled-tools';
import RouterLink from 'src/components/core/links/router-link';
import isEmpty from 'lodash/isEmpty';
import styled, { css } from 'styled-components';

/**
 * `ButtonProps` type.
 */

type ButtonProps = {
  as?: any,
  disabled?: boolean,
  href?: string,
  isActive?: boolean,
  onClick?: () => void,
  to?: string,
  type?: string
}

/**
 * `Props` type.
 */

type Props = ButtonProps & {
  children: ReactNode,
  className?: string,
  onClose?: () => void
}

/**
 * `EntryButton` styled component.
 */

const EntryButton = styled.button.attrs<ButtonProps>(({ as, href, to, type }) => {
  const element = as || to && RouterLink || href && 'a' || 'button';

  return {
    as: element,
    href: href ?? to,
    type: type || (element === 'button' ? 'button' : null)
  };
})<ButtonProps>`
  ${theme('typography.styles.h4')}

  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  border: none;
  border-radius: ${units(1)};
  color: ${color('grey800')};
  cursor: pointer;
  display: inline-block;
  outline: none;
  padding: ${units(0.5)} ${units(2)};
  text-align: left;
  text-decoration: none;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, color;

  &:focus,
  &:hover {
    background-color: ${color('yellow200')};
    color: ${color('black')};
  }

  ${ifProp('isActive', css`
    background-color: ${color('yellow500')} !important;
    color: ${color('black')};
    cursor: default;
    pointer-events: none;
  `)}

  ${ifProp('disabled', css`
    cursor: default;
    pointer-events: none;
  `)}
`;

/**
 * `SubEntryButton` styled component.
 */

const SubEntryButton = styled(EntryButton)`
  ${theme('typography.styles.small')}

  padding: ${units(1)} ${units(2)};

  ${ifProp('disabled', css`
    opacity: 0.5;
  `)}
`;

/**
 * Export `SubEntryWrapper` styled component.
 */

export const SubEntryWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: ${units(0.5)} ${units(1.5)};
`;

/**
 * Export `Entry` component.
 */

export const Entry = ({ children, onClose, ...props }: Props) => {
  const { disabled, href, to } = props;
  const isDisabled = disabled || isEmpty(href) && isEmpty(to);

  return (
    <div onClick={onClose}>
      <EntryButton
        disabled={isDisabled}
        {...props}
        {...href && {
          rel: 'noopener',
          target: '_blank'
        }}
      >
        {children}
      </EntryButton>
    </div>
  );
};

/**
 * Export `SubEntry` component.
 */

export const SubEntry = ({ children, onClose, ...props }: Props) => {
  const { disabled, href, to } = props;
  const isDisabled = disabled || isEmpty(href) && isEmpty(to);

  return (
    <div onClick={onClose}>
      <SubEntryButton
        disabled={isDisabled}
        {...props}
        {...href && {
          rel: 'noopener',
          target: '_blank'
        }}
      >
        {children}
      </SubEntryButton>
    </div>
  );
};
