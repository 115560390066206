
/**
 * Module dependencies.
 */

import { Fill } from '@untile/react-components';
import { ReactNode } from 'react';
import { Type } from 'src/components/core/typography';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, theme } from 'styled-tools';
import IconButton from 'src/components/core/buttons/icon-button';
import ModalPortal from './modal-portal';
import closeIcon from 'src/assets/svg/close.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` types.
 */

type Props = {
  children?: ReactNode,
  className?: string,
  isOpen?: boolean,
  onRequestClose?: () => void
}

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  max-height: 100vh;
  overflow: auto;
  padding: ${units(2)};
  width: 100%;
`;

/**
 * `Overlay` styled component.
 */

const Overlay = styled(Fill)<Pick<Props, 'isOpen'>>`
  align-items: center;
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  background-color: ${color.transparentize('black', 0.2)};
  display: flex;
  justify-content: center;
  overflow: auto;
  position: fixed;
  scroll-snap-type: y mandatory;
  z-index: ${theme('zIndex.modal')};

  ${ifProp('isOpen', css`
    animation-name: ${theme('keyframes.fadeIn')};
  `, css`
    animation-name: ${theme('keyframes.fadeOut')};
    opacity: 0;
    pointer-events: none;
  `)}
`;

/**
 * `ModalBody` styled component.
 */

const ModalBody = styled.div<Pick<Props, 'isOpen'>>`
  animation-duration: 0.25s;
  animation-fill-mode: both;
  animation-timing-function: ease;
  background-color: ${color('white')};
  border-radius: ${units(2.5)};
  height: max-content;
  margin: 0 auto;
  max-width: ${units(76)};
  min-height: 100px;
  overflow: hidden;
  padding: ${units(5)};
  position: relative;
  width: 100%;

  ${ifProp('isOpen', css`
    animation-name: ${theme('keyframes.fadeInUp')};
  `, css`
    animation-name: ${theme('keyframes.fadeOutDown')};
    opacity: 0;
    pointer-events: none;
  `)}
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled(IconButton).attrs({
  icon: closeIcon,
  iconSize: units(3)
})`
  position: absolute;
  right: ${units(3.5)};
  top: ${units(3)};
  z-index: 1;
`;

/**
 * Export `Title` styled component.
 */

export const Title = styled(Type.H1).attrs({ as: 'h3' })`
  margin-bottom: ${units(1)};
`;

/**
 * Export `Description` styled component.
 */

export const Description = styled(Type.H4).attrs({ as: 'p' })`
  margin-bottom: ${units(3)};
`;

/**
 * `Modal` component.
 */

const Modal = (props: Props) => {
  const { children, className, isOpen, onRequestClose } = props;

  return (
    <ModalPortal isOpen={isOpen}>
      <Overlay isOpen={isOpen}>
        <ContentWrapper>
          <ModalBody
            className={className}
            isOpen={isOpen}
            role={'dialog'}
          >
            {onRequestClose && <CloseButton onClick={onRequestClose} />}

            {children}
          </ModalBody>
        </ContentWrapper>
      </Overlay>
    </ModalPortal>
  );
};

/**
* Export `Modal` component.
*/

export default Modal;
