/**
 * Module dependencies.
 */

import { Fill, color, media } from '@untile/react-components';
import { ReactNode, useEffect, useState } from 'react';
import { ifProp, switchProp, theme } from 'styled-tools';
import { svgBackground } from 'src/styles/svg';
import { useRouter } from 'next/router';
import Loading from 'src/components/core/loading';
import brandingShape from 'src/assets/svg/branding-shape.svg';
import styled, { css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';

/**
 * `Animation` type.
 */

type Animation = 'start' | 'end';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ visible: boolean }>`
  position: relative;
  transition: opacity ${theme('animations.defaultTransition')};

  ${ifProp(
    'visible',
    css`
      opacity: 1;
      transition-delay: 0s;
    `,
    css`
      opacity: 0;
      transition-delay: 0.6s;
    `
  )}
`;

/**
 * `AnimatedBackground` styled component.
 */

const AnimatedBackground = styled(Fill)<{ animation: Animation }>`
  align-items: center;
  animation-duration: 0.6s;
  animation-fill-mode: both;
  animation-timing-function: ${theme('animations.bezier.easeOutQuad')};
  background-color: ${color('yellow100')};
  display: flex;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  z-index: ${theme('zIndex.pageTransition')};

  ${media.min('md')`
    ${svgBackground(brandingShape)}

    background-attachment: fixed;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 120% auto;
  `}

  ${media.min('xl')`
    background-size: 100% auto;
  `}

  ${switchProp('animation', {
    end: css`
      animation-name: ${theme('keyframes.fadeOut')};
      pointer-events: none;
    `,
    start: css`
      animation-name: ${theme('keyframes.fadeIn')};
      pointer-events: all;
    `
  })}
`;

/**
 * `PageTransition` component.
 */

const PageTransition = ({ children }: Props) => {
  const router = useRouter();
  const [animation, setAnimation] = useState<Animation>();

  useEffect(() => {
    setAnimation('end');
  }, []);

  useEffect(() => {
    const handleRouteChangeStart = (url, { shallow }) => {
      if (shallow) {
        return;
      }

      setAnimation('start');
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router.events, router.pathname]);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      setAnimation('end');
    };

    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.on('routeChangeError', handleRouteChangeComplete);
    };
  }, [router.events]);

  useBodyScroll({ off: animation === 'start' });

  return (
    <>
      <AnimatedBackground animation={animation}>
        <Loading active={animation === 'start'} />
      </AnimatedBackground>

      <Wrapper visible={animation === 'end'}>{children}</Wrapper>
    </>
  );
};

/**
 * Export `PageTransition` component.
 */

export default PageTransition;
