
/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { color, media, units } from '@untile/react-components/dist/styles';
import { svgBackground } from 'src/styles/svg';
import { theme } from 'styled-tools';
import PageTransition from 'src/components/page-transition';
import brandingShape from 'src/assets/svg/branding-shape.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode
}

/**
 * Export `LayoutDefaultGrid` styled component.
 */

export const LayoutDefaultGrid = styled.div`
  background-color: ${color('yellow100')};
  display: grid;
  grid-template-areas:
    '. .    .       .    .'
    '. menu menu    menu .'
    '. .    content .    .';
  grid-template-columns: ${units(0.5)} ${units(2.5)} 1fr ${units(2.5)} ${units(0.5)};
  grid-template-rows: ${units(0.5)} ${units(7)} minmax(calc(100% - ${units(7.5)}), max-content);
  min-height: 100vh;
  width: 100%;

  ${media.min('md')`
    ${svgBackground(brandingShape)}

    background-attachment: fixed;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 120% auto;
    grid-template-areas:
      '. menu . .         .'
      '. menu . content   .';
    grid-template-columns: ${units(4)} ${theme('dimensions.menuWidth')}px ${units(7)} 1fr ${units(7)};
    grid-template-rows: ${units(6)} minmax(calc(100% - ${units(6)}), max-content);
  `}

  ${media.min('xl')`
    background-size: 100% auto;
  `}
`;

/**
 * Export `LayoutDefaultContent` styled component.
 */

export const LayoutDefaultContent = styled.div`
  grid-area: content;
`;

/**
 * `DefaultLayout` component.
 */

const DefaultLayout = (props: Props) => {
  const { children } = props;

  return (
    <PageTransition>
      <LayoutDefaultGrid>
        <LayoutDefaultContent>
          {children}
        </LayoutDefaultContent>
      </LayoutDefaultGrid>
    </PageTransition>
  );
};

/**
 * Export `DefaultLayout` component.
 */

export default DefaultLayout;
