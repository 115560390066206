
/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { Type } from 'src/components/core/typography';
import { ifProp } from 'styled-tools';
import { media, units } from '@untile/react-components/dist/styles';
import CreditsWidget from 'src/components/credits-widget';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children?: ReactNode,
  className?: string,
  hideCreditsWidget?: boolean,
  title: string
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr max-content;
  margin-bottom: ${units(4)};
`;

/**
 * `CreditsWrapper` styled component.
 */

const CreditsWrapper = styled.div<{ hasChildren: boolean }>`
  ${ifProp('hasChildren', css`
    margin-right: ${units(2)};
  `)}

  ${media.max('md')`
    bottom: 0;
    margin: ${units(3)} !important;
    position: fixed;
    right: 0;
    z-index: 9;
  `}
`;

/**
 * `Actions` styled component.
 */

const Actions = styled.div`
  align-items: center;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: ${units(1)};
`;

/**
 * `Header` component.
 */

const Header = (props: Props) => {
  const { children, className, hideCreditsWidget, title } = props;

  return (
    <Wrapper className={className}>
      <Type.H1>
        {title}
      </Type.H1>

      <Actions>
        {!hideCreditsWidget && (
          <CreditsWrapper hasChildren={!!children}>
            <CreditsWidget />
          </CreditsWrapper>
        )}

        {children}
      </Actions>
    </Wrapper>
  );
};

/**
 * Export `Header`.
 */

export default Header;
