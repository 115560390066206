
/**
 * Module dependencies.
 */

import {
  Entry,
  SubEntry,
  SubEntryWrapper
} from 'src/components/menu/entries';

import { Fragment } from 'react';
import { SubmenuEntry } from 'src/types/menu';
import { Type } from 'src/components/core/typography';
import { color, units } from '@untile/react-components/dist/styles';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import IconButtonSquared from './icon-button-squared';
import caretLeft from 'src/assets/svg/caret-left.svg';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  entries: Array<SubmenuEntry>,
  onClose?: () => void,
  title: string
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: flex-start;
  background-color: ${color('white')};
  border-radius: ${units(2.5)};
  box-shadow: 0 ${units(1)} ${units(4)} ${color.transparentize('black', 0.1)};
  display: flex;
  flex-direction: column;
  min-width: ${units(46)};
  padding: ${units(3)} ${units(4)} ${units(3)} ${units(10)};
  width: min-content;
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${units(1)};
  width: 100%;
`;

/**
 * `Submenu` component.
 */

const Submenu = (props: Props) => {
  const { entries, onClose, title } = props;
  const { t } = useTranslation();
  const router = useRouter();

  return (
    <Wrapper>
      <Header>
        <Type.H2 paddingLeft={units(2)}>
          {title}
        </Type.H2>

        <IconButtonSquared
          icon={caretLeft}
          iconSize={units(2)}
          onClick={onClose}
        />
      </Header>

      {entries.map(({ label, submenu, ...rest }, indexEntry: number) => (
        <Fragment key={indexEntry}>
          <Entry
            {...rest}
            isActive={router.asPath.startsWith(rest.to)}
            onClose={onClose}
          >
            {t(label)}
          </Entry>

          {!isEmpty(submenu) && (
            <SubEntryWrapper>
              {submenu.map(({ label, ...subRest }, index: number) => (
                <SubEntry
                  {...subRest}
                  isActive={router.asPath.startsWith(subRest.to)}
                  key={index}
                  onClose={onClose}
                >
                  {t(label)}
                </SubEntry>
              ))}
            </SubEntryWrapper>
          )}
        </Fragment>
      ))}
    </Wrapper>
  );
};

/**
 * Export `Submenu` component.
 */

export default Submenu;
