
/**
 * Module dependencies.
 */

import {
  QueryObserverBaseResult,
  QueryObserverOptions,
  useQuery
} from 'react-query';

import { Token } from 'src/types/api';
import { User } from 'src/types/user';
import { getMe } from './get-me';
import { useMemo } from 'react';

/**
 * `Options` type.
 */

type Options = QueryObserverOptions<User | undefined, any, any, any> & {
  token: Token
};

/**
 * `Result` type.
 */

type Result = QueryObserverBaseResult<User | undefined, unknown>;

/**
 * Action type.
 */

const actionType = 'get-me';

/**
* `useMe` hook.
*/

function useMe(options: Options): Result {
  const { token, ...rest } = options;
  const variables = useMemo(() => ({ token }), [token]);

  return useQuery(actionType, () => getMe(variables), rest);
}

/**
 * Export `useMe` hook.
 */

export default useMe;
