
/**
 * Module dependencies.
 */

import { Token } from 'src/types/api';
import {
  getApiEndpoint,
  handleRequestError,
  setAuthorizationHeader
} from 'src/core/utils/requests';

import request from 'src/core/api-config/axios-instance';

/**
 * `Props` type.
 */

type Props = {
  ssr?: boolean,
  token?: Token
};

/**
 * Export `getMe`.
 */

export async function getMe({ ssr, token }: Props) {
  const endpoint = getApiEndpoint('getMe');

  try {
    const { data } = await request.get(endpoint, {
      headers: setAuthorizationHeader(token)
    });

    return data?.data;
  } catch (error) {
    if (!ssr) {
      throw handleRequestError(error);
    }
  }
}
