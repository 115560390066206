
/**
 * Platform api endpoints.
 */

const platformApiEndpoints = {
  createContact: '/platform/contacts',
  createList: '/platform/lists',
  createListContacts: '/platform/lists/:id/contacts',
  createMeOtp: '/platform/me/otp',
  createMessage: '/platform/messages',
  createMessageCost: '/platform/messages/cost',
  createUser: '/platform/users',
  deleteContact: '/platform/contacts/:id',
  deleteContacts: '/platform/contacts',
  deleteList: '/platform/lists/:id',
  deleteListContacts: '/platform/lists/:id/contacts',
  deleteMeOtp: '/platform/me/otp',
  getApiKeys: '/platform/api-keys',
  getContact: '/platform/contacts/:id',
  getContacts: '/platform/contacts',
  getCredits: '/platform/credits',
  getList: '/platform/lists/:id',
  getLists: '/platform/lists',
  getMe: '/platform/me',
  getMessageStats: '/platform/me/message-sent-stats',
  getMessages: '/platform/messages',
  patchApiKey: '/platform/api-keys/:id',
  revokeApiKey: '/platform/api-keys/:id/revoke',
  updateContact: '/platform/contacts/:id',
  updateList: '/platform/lists/:id',
  updateMe: '/platform/me'
};

/**
 * Payment api endpoints.
 */

const paymentEndpoints = {
  createTaxId: '/payment/customer/taxId',
  deleteTaxId: '/payment/customer/taxId/:id',
  editTaxId: '/payment/customer/taxId/:id',
  getBilling: '/payment/customer/invoices',
  getCreditRates: '/payment/credits/rates',
  getCustomer: '/payment/customer',
  getProductPrices: '/payment/product/prices',
  invoicePayment: '/payment/credits/invoice',
  updateCustomer: '/payment/customer'
};

/**
 * Export `apiEndpoints`.
 */

export const apiEndpoints = {
  ...paymentEndpoints,
  ...platformApiEndpoints,
  changeUserPassword: '/users/password/change',
  getAuthToken: '/auth/token',
  getCountries: '/countries',
  recoverUserPassword: '/users/password/forgot',
  resetUserPassword: '/users/password/reset',
  revokeAuthToken: '/auth/revoke'
};
