
/**
 * Module dependencies.
 */

import { MenuProfileEntry } from 'src/types/menu';
import { routes } from './routes';

/**
 * Export `profileMenu`.
 */

export const profileMenu: Array<MenuProfileEntry> = [{
  label: 'common:menu.profile.data',
  to: routes.account.profile
}, {
  label: 'common:menu.profile.credits',
  to: routes.account.credits
}, {
  label: 'common:menu.profile.billing',
  to: routes.account.billing
}, {
  label: 'common:menu.profile.password',
  to: routes.account.changePassword
}, {
  label: 'common:menu.profile.security',
  to: routes.account.security
}, {
  isExternal: true,
  label: 'common:menu.profile.account'
}];
