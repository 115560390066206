/**
 * Google tag manager dd.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * Export `pageView`.
 */

export function pageView(url: string) {
  if (googleTagManagerId) {
    // @ts-ignore
    window.dataLayer.push({
      event: 'pageview',
      page: url
    });
  }
}

/**
 * Export `pageEvent`.
 */

export function pageEvent(event: string) {
  if (googleTagManagerId) {
    // @ts-ignore
    window.dataLayer.push({ event });
  }
}
