
/**
 * Module dependencies.
 */

import axios from 'axios';
import qs from 'qs';

/**
 * Export `request`.
 */

const axiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
  paramsSerializer: {
    serialize: params => qs.stringify(params)
  },
  withCredentials: true
});

/**
 * Axios instance request.
 */

axiosInstance.interceptors.request.use(request => {
  return request;
}, error => {
  return Promise.reject(error);
});

/**
 * Axios instance response.
 */

axiosInstance.interceptors.response.use(response => {
  return response;
}, error => {
  return Promise.reject(error);
});

/**
 * Export `axiosInstance`.
 */

export default axiosInstance;

