
/**
 * Module dependencies.
 */

import { ButtonHTMLAttributes, ElementType, forwardRef } from 'react';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, theme } from 'styled-tools';
import { isExternalUrl } from '@untile/react-components/dist/utils';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: ElementType;
  className?: string;
  href?: string;
  icon: string;
  iconSize: string | ((props: any) => string);
  isActive?: boolean;
  rel?: string;
  target?: string;
};

/**
 * `Hover` styled components.
 */

const Hover = styled.span`
  background-color: ${color('yellow200')};
  border-radius: 50%;
  height: 100%;
  left: 50%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%) scale(0);
  transform-origin: center;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, transform;
  width: 100%;
`;

/**
 * `Button` styled component.
 */

const Button = styled.button.attrs<Props>(({ as, href, type }) => {
  const isExternal = isExternalUrl(href);
  const element = as || href && !isExternal && RouterLink || href && isExternal && 'a' || 'button';

  return {
    as: element,
    type: type || (element === 'button' ? 'button' : null)
  };
})<{ size: string }>`
  -webkit-tap-highlight-color: transparent;
  appearance: none;
  background: none;
  border: none;
  color: ${color('grey900')};
  cursor: pointer;
  line-height: 0;
  outline: none;
  padding: ${units(1)};
  position: relative;
  transition: opacity ${theme('animations.defaultTransition')};

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.6;
    pointer-events: none;
  `)}

  :hover ${Hover} {
    transform: translate(-50%, -50%) scale(1);
  }

  ${ifProp('isActive', css`
    cursor: default;
    pointer-events: none;

    ${Hover} {
      transform: translate(-50%, -50%) scale(1);
    }
  `)}
`;

/**
 * `IconButton` component.
 */

const IconButton = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const { icon, iconSize, ...rest } = props;

  return (
    <Button
      {...rest}
      ref={ref}
      size={iconSize}
    >
      <Hover />

      <Svg
        icon={icon}
        size={iconSize}
      />
    </Button>
  );
});

/**
 * `IconButton` display name.
 */

IconButton.displayName = 'IconButton';

/**
 * Export `IconButton` component.
 */

export default IconButton;
