/**
 * Module dependencies.
 */

import { AppProps } from 'src/types/app';
import { Crisp } from 'crisp-sdk-web';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle as UntileGlobalStyle } from '@untile/react-components';
import { appWithTranslation } from 'next-i18next';
import { createTheme } from '@untile/react-components/dist/styles';
import { pageView } from 'src/core/utils/analytics';
import { theme } from 'src/styles/theme';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import GlobalStyle from 'src/components/core/global-style';
import Head from 'next/head';
import Layout from 'src/components/layout';
import Script from 'next/script';
import SessionProvider from 'src/context/session/provider';
import SnackbarProvider from 'src/context/snackbar/provider';
import packageJson from 'package.json';

/**
 * Google tag manager dd.
 */

const googleTagManagerId = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;

/**
 * `crispWebsiteId` constant.
 */

const crispWebsiteId = process.env.NEXT_PUBLIC_CRISP_WEBSITE_ID;

/**
 * `isProduction` constant.
 */

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * Query cache.
 */

const queryClient = new QueryClient();

/**
 * `PageApp` page.
 */

const PageApp = (props: AppProps) => {
  const {
    Component,
    pageProps: { layout, ...pageProps }
  } = props;

  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', pageView);

    return () => {
      router.events.off('routeChangeComplete', pageView);
    };
  }, [router.events]);

  useEffect(() => {
    if (!isProduction || !crispWebsiteId) {
      return;
    }

    Crisp.configure(crispWebsiteId);
  });

  return (
    <>
      <Head>
        <meta content={'IE=edge'} httpEquiv={'X-UA-Compatible'} />

        <meta content={'text/html;charset=utf-8'} httpEquiv={'Content-Type'} />

        <meta
          content={'width=device-width, initial-scale=1'}
          name={'viewport'}
        />

        <meta content={'true'} name={'HandheldFriendly'} />

        <meta content={packageJson.version} name={'version'} />

        <link
          href={'/favicon.ico'}
          rel={'shortcut icon'}
          type={'image/x-icon'}
        />

        <link
          href={'/favicons/apple-touch-icon.png'}
          rel={'apple-touch-icon'}
          sizes={'180x180'}
        />

        <link
          href={'/favicons/favicon-32x32.png'}
          rel={'icon'}
          sizes={'32x32'}
          type={'image/png'}
        />

        <link
          href={'/favicons/favicon-16x16.png'}
          rel={'icon'}
          sizes={'16x16'}
          type={'image/png'}
        />

        <link href={'/site.webmanifest'} rel={'manifest'} />

        <meta content={'#ffc531'} name={'msapplication-TileColor'} />

        <meta content={'#ffffff'} name={'theme-color'} />
      </Head>

      {googleTagManagerId && (
        <Script
          dangerouslySetInnerHTML={{
            // eslint-disable-next-line id-match
            __html: `
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${googleTagManagerId}');
            `
          }}
          id={'google-tag-manager'}
          strategy={'afterInteractive'}
        />
      )}

      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={createTheme(theme)}>
          <SnackbarProvider>
            <SessionProvider>
              <UntileGlobalStyle />

              <GlobalStyle />

              <Layout type={layout}>
                <Component {...pageProps} />
              </Layout>
            </SessionProvider>
          </SnackbarProvider>
        </ThemeProvider>

        <ReactQueryDevtools initialIsOpen={!isProduction} />
      </QueryClientProvider>
    </>
  );
};

/**
 * Export `PageApp` page.
 */

export default appWithTranslation(PageApp);
