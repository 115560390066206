
/**
 * Module dependencies.
 */

import { Context, createContext, useContext } from 'react';
import { Token } from 'src/types/api';
import { UserSession } from 'src/types/session';

/**
 * Export `SessionContext` context.
 */

export const SessionContext: Context<{
  isAuthenticated?: boolean,
  isLoading?: boolean,
  isSuccess?: boolean,
  onClearToken?: () => void,
  onUpdateToken?: (token: Token) => void,
  onUpdateUser?: () => void,
  token?: Token,
  user?: UserSession
}> = createContext({});

/**
 * Export `useSession` hook.
 */

export const useSession = () => useContext(SessionContext);

