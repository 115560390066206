
/**
 * Module dependencies.
 */

import { Entry } from './entries';
import { color, media, units } from '@untile/react-components/dist/styles';
import { cookiesList } from 'src/core/app-config';
import { parseCookies, setCookie } from 'nookies';
import { routes } from 'src/core/routes';
import { theme } from 'styled-tools';
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useSession } from 'src/context/session/context';
import { useSnackbar } from 'src/context/snackbar/context';
import { useTranslation } from 'next-i18next';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  onClose?: () => void
}

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-auto-rows: min-content;
  grid-row-gap: ${units(0.5)};
  min-width: ${theme('dimensions.submenuProfileWidthMobile')}px;
  padding: ${units(0.5)};

  ${media.min('md')`
    min-width: ${theme('dimensions.submenuProfileWidth')}px;
  `}
`;

/**
 * `Divider` styled component.
 */

const Divider = styled.div`
  background-color: ${color('grey50')};
  height: 1px;
  margin: auto;
  width: 80%;
`;

/**
 * `StyledEntry` styled component.
 */

const StyledEntry = styled(Entry)`
  ${theme('typography.styles.p')}

  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  padding: ${units(1.5)};
  pointer-events: all;
  width: 100%;
`;

/**
 * `SubmenuProfile` component.
 */

const SubmenuProfile = ({ onClose }: Props) => {
  const { t } = useTranslation();
  const { showMessage } = useSnackbar();
  const { onClearToken } = useSession();
  const cookies = parseCookies();
  const router = useRouter();
  const isActive = (to: string) => router.asPath.startsWith(to);
  const handleLogout = useCallback(() => {
    onClearToken();
    showMessage(
      t('common:signOut.successMessage'),
      { appearance: 'success' }
    );

    if (cookies[cookiesList.welcome] !== 'true') {
      setCookie(null, cookiesList.welcome, 'true', {
        maxAge: 30 * 24 * 60 * 60,
        path: '/'
      });
    }

    router.push(routes.signIn);
  }, [cookies, onClearToken, router, showMessage, t]);

  return (
    <Wrapper>
      <StyledEntry
        isActive={isActive(routes.account.profile)}
        onClose={onClose}
        to={routes.account.profile}
      >
        {t('common:menu.profile.label')}
      </StyledEntry>

      <Divider />

      <StyledEntry
        disabled={false}
        onClick={handleLogout}
        onClose={onClose}
      >
        {t('common:actions.logout')}
      </StyledEntry>
    </Wrapper>
  );
};

/**
 * Export `SubmenuProfile` component.
 */

export default SubmenuProfile;
