
/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import NextLink, { LinkProps } from 'next/link';

/**
 * `Props` type.
 */

type Props = LinkProps & {
  children: ReactNode;
  className?: string;
};

/**
 * `RouterLink` component.
 */

const RouterLink = ({ children, ...props }: Props) => (
  <NextLink {...props}>{children}</NextLink>
);

/**
 * Export `RouterLink` component.
 */

export default RouterLink;
