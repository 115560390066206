
/**
 * Module dependencies.
 */

import {
  ApiEndpoint,
  ApiError,
  Headers,
  Interpolations,
  Token
} from 'src/types/api';

import { apiEndpoints } from 'src/core/api-config/endpoints';
import { regexes } from 'src/core/utils/regexes';
import get from 'lodash/get';
import template from 'lodash/template';

/**
 * Export `setAuthorizationHeader`.
 */

export function setAuthorizationHeader(token: Token) {
  if (!token) {
    return;
  }

  return {
    Authorization: `Bearer ${token}`
  };
}

/**
 * Export `getOtpTokenHeader`.
 */

export function getOtpTokenHeader(headers: Headers) {
  return get(headers, 'otp-token');
}

/**
 * Export `setOtpTokenHeader`.
 */

export function setOtpTokenHeader(token: Token) {
  if (!token) {
    return;
  }

  return {
    'OTP-Token': token
  };
}

/**
 * Export `handleRequestError` method.
 */

export const handleRequestError = (error: any): ApiError => {
  return error?.response ?? error;
};

/**
 * Export `getApiEndpoint`.
 */

export function getApiEndpoint(endpointName: ApiEndpoint, interpolations?: Interpolations): string {
  const endpoint = apiEndpoints[endpointName];

  if (interpolations) {
    return template(endpoint, {
      interpolate: regexes.interpolateUrl
    })(interpolations);
  }

  return endpoint;
}
