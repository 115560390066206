/**
 * Module dependencies.
 */

import {
  Fragment,
  MutableRefObject,
  ReactNode,
  RefObject,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import { useDetectOutsideClick } from 'src/hooks/use-detect-outside-click';
import { useElementPosition } from 'src/hooks/use-element-position';
import { useRouter } from 'next/router';
import ModalPortal from 'src/components/core/modals/modal-portal';
import styled, { css } from 'styled-components';

/**
 * Constants.
 */

const defaultWidth = 200;

/**
 * `ButtonProps` type.
 */

type ButtonProps = {
  'aria-expanded': boolean;
  'aria-haspopup': boolean;
  onClick?: () => void;
  onFocus?: () => void;
  ref?: MutableRefObject<HTMLButtonElement>;
  role: string;
  tabIndex: number;
};

/**
 * `Alignment` type.
 */

type Alignment = 'center' | 'left' | 'right';

/**
 * Export `DropdownProps` type.
 */

export type DropdownProps = {
  alignment?: Alignment;
  children?: ReactNode;
  className?: string;
  hasCaret?: boolean;
  isRenderPortal?: boolean;
  openOnFocus?: boolean;
  parentRef?: RefObject<HTMLDivElement>;
  renderButton: (props: ButtonProps) => JSX.Element;
  renderChildren?: (props: { onRequestClose: () => void }) => JSX.Element;
  width?: string;
};

/**
 * `ControlledDropdownProps` type.
 */

type ControlledDropdownProps = DropdownProps & {
  active: boolean;
  onClose: () => void;
  onToggle?: () => void;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

/**
 * Alignment properties.
 */

const alignmentProperties = (margin: string) => ({
  center: css`
    left: 50%;
  `,
  left: css`
    left: ${margin};
  `,
  right: css`
    right: ${margin};
  `
});

/**
 * `Menu` styled component.
 */

const Menu = styled.div<
  Pick<DropdownProps, 'hasCaret' | 'width'> & {
    alignment?: Alignment;
    isActive: boolean;
  }
>`
  background-color: ${color('white')};
  border-radius: ${units(1)};
  filter: drop-shadow(
    0 ${units(1)} ${units(4)} ${color.transparentize('black', 0.1)}
  );
  max-height: 200px;
  opacity: 0;
  overflow: auto;
  position: absolute;
  top: calc(100% + ${units(2)});
  transform: translate(
    ${ifProp({ alignment: 'center' }, '-50%', 0)},
    ${units(3)}
  );
  transition: ${theme('animations.defaultTransition')};
  transition-property: opacity, transform, visibility;
  visibility: hidden;
  width: ${prop('width', `${defaultWidth}px`)};
  z-index: ${theme('zIndex.dropdown')};

  ${switchProp('alignment', alignmentProperties('0'))};

  ${ifProp(
    'isActive',
    css`
      opacity: 1;
      transform: translate(${ifProp({ alignment: 'center' }, '-50%', 0)}, 0);
      visibility: visible;
    `
  )}

  ${ifProp(
    'hasCaret',
    css`
      &::before {
        border-bottom: ${units(1.5)} solid ${color('white')};
        border-left: ${units(1.25)} solid transparent;
        border-right: ${units(1.25)} solid transparent;
        content: '';
        height: 0;
        position: absolute;
        top: -10px;
        width: 0;

        ${ifProp(
          { alignment: 'center' },
          css`
            transform: translateX(-50%);
          `
        )}

        ${switchProp('alignment', alignmentProperties('8px'))};
      }
    `
  )}
`;

/**
 * Export `ControlledDropdown` component.
 */

export const ControlledDropdown = forwardRef<
  HTMLDivElement,
  ControlledDropdownProps
>((props, menuRef) => {
  const {
    active,
    alignment,
    className,
    hasCaret = true,
    isRenderPortal,
    onClose,
    onToggle,
    openOnFocus,
    parentRef,
    renderButton,
    width = `${defaultWidth}px`
  } = props;

  const Element = isRenderPortal ? ModalPortal : Fragment;
  const {
    position,
    ref: buttonRef,
    updatePosition
  } = useElementPosition({ off: !isRenderPortal });

  const leftAlignment = useMemo(() => {
    if (!isRenderPortal) {
      return;
    }

    switch (alignment) {
      case 'center':
        return (position?.right + position?.left) / 2;

      case 'left':
        return position?.left;

      case 'right':
        return position?.right - Number(width.replace(/\D/g, ''));

      default:
        return (position?.right + position?.left) / 2;
    }
  }, [alignment, isRenderPortal, position?.left, position?.right, width]);

  useEffect(() => {
    const referenceRef = parentRef?.current;

    if (referenceRef) {
      referenceRef.addEventListener('scroll', updatePosition);

      return () => referenceRef.removeEventListener('scroll', updatePosition);
    }
  }, [parentRef, updatePosition]);

  return (
    <Wrapper
      className={className}
      ref={buttonRef as MutableRefObject<HTMLDivElement>}
    >
      {renderButton({
        'aria-expanded': active,
        'aria-haspopup': true,
        role: 'button',
        tabIndex: 0,
        ...(!openOnFocus
          ? {
              onClick: onToggle
            }
          : {
              onFocus: onToggle
            })
      })}

      <Element {...(isRenderPortal && { isOpen: active })}>
        <Menu
          alignment={alignment}
          hasCaret={hasCaret}
          isActive={active}
          ref={menuRef}
          role={'menu'}
          {...(isRenderPortal && {
            style: {
              left: leftAlignment,
              top: position?.top + 32
            }
          })}
          tabIndex={-1}
          width={width}
        >
          {!!props.children && props.children}

          {!!props.renderChildren &&
            props.renderChildren({
              onRequestClose: onClose
            })}
        </Menu>
      </Element>
    </Wrapper>
  );
});

ControlledDropdown.displayName = 'ControlledDropdown';

/**
 * `Dropdown` component.
 */

const Dropdown = (props: DropdownProps) => {
  const router = useRouter();
  const ref = useRef<HTMLDivElement>();
  const [active, setActive] = useState<boolean>(false);

  useDetectOutsideClick(ref, () => setActive(false));

  useEffect(() => {
    const handleRouteChange = () => {
      if (active) {
        setActive(false);
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [active, router.events]);

  return (
    <ControlledDropdown
      {...props}
      active={active}
      onClose={() => setActive(false)}
      onToggle={() => setActive(active => !active)}
      ref={ref}
    />
  );
};

/**
 * Export `Dropdown` component.
 */

export default Dropdown;
