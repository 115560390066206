/**
 * Module dependencies.
 */

import { Credit } from 'src/types/credits';
import { intervalToDuration } from 'date-fns';
import { useMemo } from 'react';
import head from 'lodash/head';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import useCredits from 'src/api/credits/use-credits';

/**
 * Sum available credits.
 */

function sumAvailableCredits(credits: Credit[]): number {
  return credits?.reduce((acc, credit) => {
    return acc + parseInt(credit.availableCredits, 10);
  }, 0);
}

/**
 * Export `useUserCredits` hook.
 */

export function useUserCredits() {
  const { data, status } = useCredits({
    params: {
      sort: 'expiryAt'
    }
  });

  const credits = useMemo(() => {
    const [bought, offered] = partition(data, ({ freeAt }) => isEmpty(freeAt));
    const totalBought = sumAvailableCredits(bought);
    const totalOffered = sumAvailableCredits(offered);

    return {
      total: totalBought + totalOffered,
      totalBought,
      totalOffered
    };
  }, [data]);

  const creditsExpire = useMemo(() => {
    const first = head(data) as Credit | null;

    if (first && first?.expiryAt) {
      const interval = intervalToDuration({
        end: new Date(first?.expiryAt),
        start: new Date()
      });

      return interval?.years === 0 && interval.months < 1
        ? {
            days: interval.days,
            total: first.availableCredits
          }
        : null;
    }

    return null;
  }, [data]);

  return {
    credits,
    creditsExpire,
    data,
    status
  };
}
