
/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { useBreakpoint } from '@untile/react-components';
import { useRouter } from 'next/router';
import AccountLayout from './account';
import DefaultLayout from './default';
import Menu from 'src/components/menu/desktop';
import MenuMobile from 'src/components/menu/mobile';
import PageTransition from 'src/components/page-transition';
import isBoolean from 'lodash/isBoolean';

/**
 * Export `LayoutType` type.
 */

export type LayoutType = 'account' | 'account-fluid' | 'authentication' | 'default';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  type?: LayoutType
};

/**
 * `Layout` component.
 */

const Layout = ({ children, type = 'default' }: Props) => {
  const isDesktop = useBreakpoint('md', 'min');
  const router = useRouter();

  if (type === 'authentication') {
    return (
      <PageTransition>
        {children}
      </PageTransition>
    );
  }

  return (
    <>
      {isBoolean(isDesktop) && router?.isReady && (
        <>
          {isDesktop && <Menu />}

          {!isDesktop && <MenuMobile />}
        </>
      )}

      {type === 'account' || type === 'account-fluid' ? (
        <AccountLayout isFullWidth={type === 'account-fluid'}>
          {children}
        </AccountLayout>
      ) : (
        <DefaultLayout>
          {children}
        </DefaultLayout>
      )}
    </>
  );
};

/**
 * Export `Layout` component.
 */

export default Layout;
