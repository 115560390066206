/**
 * Module dependencies.
 */

import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * `Position` type.
 */

type Position = {
  left: number;
  right: number;
  top: number;
};

/**
 * Export `useElementPosition` hook.
 */

export function useElementPosition({ off }: { off?: boolean }) {
  const ref = useRef<HTMLElement>(null);
  const [position, setPosition] = useState<Position>({
    left: 0,
    right: 0,
    top: 0
  });

  const updatePosition = useCallback(() => {
    if (ref?.current && !off) {
      const { left, right, top } = ref?.current?.getBoundingClientRect() ?? {};

      setPosition({ left, right, top });
    }
  }, [off]);

  useEffect(() => {
    if (ref?.current && !off) {
      const { left, right, top } = ref?.current?.getBoundingClientRect() ?? {};

      setPosition({ left, right, top });
    }
  }, [off]);

  useEffect(() => {
    window.addEventListener('resize', updatePosition);

    return () => window.removeEventListener('resize', updatePosition);
  }, [updatePosition]);

  return { position, ref, updatePosition };
}
