
/**
 * Module dependencies.
 */

import { useEffect } from 'react';

/**
 * Export `useDetectOutsideClick` hook.
 */

export function useDetectOutsideClick(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    }, [ref, handler]
  );
}
