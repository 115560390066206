
/**
 * Module dependencies.
 */

import { User } from 'src/types/user';
import isEmpty from 'lodash/isEmpty';

/**
 * Export `getUserName`.
 */

export function getUserName(user: User | null): string {
  if (isEmpty(user)) {
    return '';
  }

  const { email, firstName, lastName } = user;
  const names = [firstName, lastName];
  const name = names.filter(name => !isEmpty(name)).join(' ');

  return isEmpty(name) ? email : name;
}
