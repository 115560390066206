
/**
 * Module dependencies.
 */

import { css } from 'styled-components';
import { setFontStyle } from '@untile/react-components';
import { switchProp } from 'styled-tools';

/**
 * Font families.
 */

const fontFamily = {
  roboto: '"Roboto", sans-serif',
  sansSerif: '"Poppins", sans-serif'
};

/**
 * Type sizes.
 */

const typeSizes = {
  display1: {
    fontFamily: fontFamily.roboto,
    fontSize: 64,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 56
  },
  display2: {
    fontFamily: fontFamily.roboto,
    fontSize: 48,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 48
  },
  display3: {
    fontFamily: fontFamily.roboto,
    fontSize: 36,
    fontWeight: 700,
    letterSpacing: 0,
    lineHeight: 40
  },
  h1: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 32,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 48
  },
  h2: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 24,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 40
  },
  h3: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 24
  },
  h4: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 16,
    fontWeight: 600,
    letterSpacing: 0,
    lineHeight: 24
  },
  h5: {
    fontFamily: fontFamily.sansSerif,
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 16
  },
  paragraph: {
    fontFamily: fontFamily.roboto,
    fontSize: 16,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 24
  },
  small: {
    fontFamily: fontFamily.roboto,
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0,
    lineHeight: 16
  }
};

/**
 * `display1` style.
 */

const display1 = css`
  ${setFontStyle(typeSizes.display1)}
`;

/**
 * `display2` style.
 */

const display2 = css`
  ${setFontStyle(typeSizes.display2)}
`;

/**
 * `display3` style.
 */

const display3 = css`
  ${setFontStyle(typeSizes.display3)}
`;

/**
 * `h1` style.
 */

const h1 = css`
  ${setFontStyle(typeSizes.h1)}
`;

/**
 * `h2` style.
 */

const h2 = css`
  ${setFontStyle(typeSizes.h2)}
`;

/**
 * `h3` style.
 */

const h3 = css`
  ${setFontStyle(typeSizes.h3)}
`;

/**
 * `h4` style.
 */

const h4 = css`
  ${setFontStyle(typeSizes.h4)}
`;

/**
 * `h5` style.
 */

const h5 = css`
  ${setFontStyle(typeSizes.h5)}
`;

/**
 * `paragraph` style.
 */

const paragraph = css`
  ${setFontStyle(typeSizes.paragraph)}

  ${switchProp('size', {
    large: css`
      font-size: 18px;
    `,
    small: css`
      font-size: 14px;
      line-height: 16px;
    `
  })}
`;

/**
 * `small` style.
 */

const small = css`
  ${setFontStyle(typeSizes.small)}

  ${switchProp('size', {
    xSmall: css`
      font-size: 10px;
      line-height: 14px;
    `
  })}
`;

/**
 * Export types to be generated.
 */

const styles = {
  h1,
  h2,
  h3,
  h4,
  h5,
  p: paragraph, // eslint-disable-line id-length
  small
};

/**
 * Export `type`.
 */

export const typography = {
  fontFamily,
  otherStyles: {
    display1,
    display2,
    display3
  },
  styles
};
