/**
 * Module dependencies.
 */

import {
  RawHtml,
  Type,
  color,
  units,
  useBreakpoint
} from '@untile/react-components';

import { ifProp, theme } from 'styled-tools';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';
import { useUserCredits } from 'src/hooks/use-user-credits';
import Button from 'src/components/core/buttons/button';
import ExpireCreditsModal from 'src/components/modals/expire-credits-modal';
import Svg from 'src/components/core/svg';
import Tooltip from 'src/components/core/tooltip';
import infoIcon from 'src/assets/svg/info.svg';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{ onClick?: () => void }>`
  align-items: center;
  background-color: ${color('white')};
  border: 1px solid ${color('primary')};
  border-radius: ${units(2)};
  box-shadow: 0 16px 24px -10px rgba(223, 209, 177, 0.4);
  display: flex;
  grid-gap: ${units(1)};
  padding: ${units(1)} ${units(2)};
  transition: background-color ${theme('animations.defaultTransition')};
  width: max-content;

  ${ifProp(
    'onClick',
    css`
      cursor: pointer;
    `
  )}

  &:focus,
  &:hover {
    background-color: ${color('yellow100')};
  }
`;

/**
 * `Amount` styled component.
 */

const Amount = styled(Type.H2)`
  cursor: pointer;
  font-family: ${theme('typography.fontFamily.roboto')};
  font-weight: 700;
  line-height: 32px;
`;

/**
 * `AvailableCreditsLabel` styled component.
 */

const AvailableCreditsLabel = styled(Type.Small)`
  color: ${color('grey600')};
`;

/**
 * `TipLabel` styled component.
 */

const TipLabel = styled.div`
  font-weight: 400;
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  margin-left: ${units(1)};
`;

/**
 * `InfoIcon` styled component.
 */

const InfoIcon = styled(Svg)`
  color: ${color('yellow600')};
`;

/**
 * `ExpireLabel` styled component.
 */

const ExpireLabel = styled(Type.Small).attrs({
  as: 'div',
  size: 'xSmall'
})`
  color: ${color('grey700')};
  transition: color ${theme('animations.defaultTransition')};
`;

/**
 * `ExpireLabelLink` styled component.
 */

const ExpireLabelLink = styled(ExpireLabel)`
  text-decoration: underline;
`;

/**
 * `CreditsExpireWrapper` styled component.
 */

const CreditsExpireWrapper = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  grid-gap: ${units(1)};
  margin-left: ${units(1)};

  &:hover {
    ${ExpireLabel} {
      color: ${color('black')};
    }
  }
`;

/**
 * `CreditsExpired` component.
 */

const CreditsExpired = ({ total }: { total: string }) => {
  const { t } = useTranslation();

  return (
    <div>
      <ExpireLabel>
        {t('common:credits.labels.creditsExpire', { total })}
      </ExpireLabel>

      <ExpireLabelLink>{t('common:credits.labels.consult')}</ExpireLabelLink>
    </div>
  );
};

/**
 * `CreditsWidget` component.
 */

const CreditsWidget = ({ className }: Props) => {
  const { t } = useTranslation();
  const isMobile = useBreakpoint('md', 'max');
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const { credits, creditsExpire, data, status } = useUserCredits();

  return (
    <>
      <Wrapper
        className={className}
        {...(credits.total > 0 && {
          onClick: () => setModalOpen(true)
        })}
      >
        <Amount>
          {status === 'success' ? (
            <>
              {isMobile ? (
                credits.total
              ) : (
                <Tooltip
                  hideIcon
                  placement={'bottom'}
                  tip={
                    <div>
                      <TipLabel>
                        {t('common:credits.labels.bought', {
                          total: credits.totalBought
                        })}
                      </TipLabel>

                      <TipLabel>
                        {t('common:credits.labels.offers', {
                          total: credits.totalOffered
                        })}
                      </TipLabel>
                    </div>
                  }
                  width={'max-content'}
                >
                  {credits.total}
                </Tooltip>
              )}
            </>
          ) : (
            0
          )}
        </Amount>

        <RawHtml element={AvailableCreditsLabel}>
          {t('common:credits.labels.availableCredits')}
        </RawHtml>

        {status === 'success' && creditsExpire && (
          <CreditsExpireWrapper>
            <InfoIcon icon={infoIcon} size={units(2)} />

            {isMobile ? (
              <CreditsExpired total={creditsExpire?.total} />
            ) : (
              <Tooltip
                hideIcon
                placement={'bottom'}
                tip={
                  <TipLabel>
                    {t('common:credits.labels.expireAt', {
                      count: creditsExpire.days
                    })}
                  </TipLabel>
                }
                width={'max-content'}
              >
                <CreditsExpired total={creditsExpire?.total} />
              </Tooltip>
            )}
          </CreditsExpireWrapper>
        )}

        {status === 'success' && credits.total === 0 && (
          <StyledButton colorTheme={'quaternary'} href={'/'}>
            {t('common:actions.buy')}
          </StyledButton>
        )}
      </Wrapper>

      {status === 'success' && credits.total > 0 && (
        <ExpireCreditsModal
          data={data}
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
        />
      )}
    </>
  );
};

/**
 * Export `CreditsWidget` component.
 */

export default CreditsWidget;
