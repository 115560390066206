/**
 * Module dependencies.
 */

import {
  ButtonHTMLAttributes,
  ElementType,
  ReactNode,
  forwardRef
} from 'react';

import { color, states, units } from '@untile/react-components/dist/styles';
import { ifProp, switchProp, theme } from 'styled-tools';
import { isExternalUrl } from '@untile/react-components/dist/utils';
import Loading from 'src/components/core/loading';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import styled, { css } from 'styled-components';

/**
 * Export `ColorTheme` type.
 */

export type ColorTheme = 'primary' | 'quaternary' | 'secondary' | 'tertiary';

/**
 * Export `ButtonProps` type.
 */

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  as?: ElementType;
  children: ReactNode;
  className?: string;
  colorTheme?: ColorTheme;
  href?: string;
  icon?: string;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isReversed?: boolean;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.button.attrs<ButtonProps>(
  ({ as, colorTheme, href, type }) => {
    const isExternal = isExternalUrl(href);
    const element =
      as ||
      (href && !isExternal && RouterLink) ||
      (href && isExternal && 'a') ||
      'button';

    return {
      as: element,
      colorTheme: colorTheme ?? 'primary',
      type: type || (element === 'button' ? 'button' : null)
    };
  }
)`
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  appearance: none;
  border: 1px solid;
  border-radius: ${units(1)};
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  line-height: 16px;
  padding: ${units(1)} ${units(2)};
  position: relative;
  transition: ${theme('animations.defaultTransition')};
  transition-property: background-color, border-color, color, opacity, width;

  ${states.action`
    outline: none;
    text-decoration: none;
  `}

  ${ifProp(
    'isReversed',
    css`
      flex-direction: row-reverse;
    `
  )}

  ${ifProp(
    'isFullWidth',
    css`
      justify-content: center;
      width: 100%;
    `,
    css`
      width: max-content;
    `
  )}

  ${ifProp(
    'isLoading',
    css`
      cursor: default;
      opacity: 0.8;
      pointer-events: none;
    `
  )}

  ${ifProp(
    'disabled',
    css`
      cursor: default;
      pointer-events: none;
    `
  )}

  ${switchProp('colorTheme', {
    primary: css`
      background-color: ${color('grey900')};
      border-color: ${color('grey900')};
      color: ${color('white')};

      &:focus,
      &:hover {
        background-color: ${color('black')};
        border-color: ${color('black')};
      }

      ${ifProp(
        'disabled',
        css`
          background-color: ${color('grey600')};
          border-color: ${color('grey600')};
          color: ${color('grey200')};
        `
      )}
    `,
    quaternary: css`
      background-color: ${color('yellow400')};
      border-color: ${color('yellow400')};
      color: ${color('black')};

      &:focus,
      &:hover {
        background-color: ${color('primary')};
        border-color: ${color('primary')};
      }

      ${ifProp(
        'disabled',
        css`
          background-color: ${color('grey600')};
          border-color: ${color('grey600')};
          color: ${color('grey200')};
        `
      )}
    `,
    secondary: css`
      background-color: transparent;
      border-color: ${color('grey900')};
      color: ${color('grey900')};

      &:focus,
      &:hover {
        background-color: ${color('yellow200')};
      }

      ${ifProp(
        'disabled',
        css`
          background-color: transparent;
          border-color: ${color('grey600')};
          color: ${color('grey600')};
        `
      )}
    `,
    tertiary: css`
      background-color: transparent;
      border: none;
      border-radius: 0;
      color: ${color('grey900')};
      font-weight: 400;
      padding: 0;
      text-decoration: underline !important;

      &:focus,
      &:hover {
        color: ${color('black')};
      }

      ${ifProp(
        'disabled',
        css`
          color: ${color('grey600')};
        `
      )}
    `
  })}
`;

/**
 * `Icon` styled component.
 */

const Icon = styled(Svg).attrs({
  size: '16px'
})<Pick<ButtonProps, 'isReversed'>>`
  ${ifProp(
    'isReversed',
    css`
      margin-left: ${units(1)};
    `,
    css`
      margin-right: ${units(1)};
    `
  )}
`;

/**
 * `StyledLoading` styled component.
 */

const StyledLoading = styled(Loading).attrs({
  relative: true,
  size: 16
})<Pick<ButtonProps, 'isReversed'>>`
  ${ifProp(
    'isReversed',
    css`
      margin-right: ${units(1)};
    `,
    css`
      margin-left: ${units(1)};
    `
  )}
`;

/**
 * `Button` component.
 */

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref) => {
    const { children, icon, isLoading, isReversed, ...rest } = props;

    return (
      <Wrapper
        {...rest}
        isLoading={isLoading}
        isReversed={isReversed}
        ref={ref}
      >
        {icon && <Icon icon={icon} isReversed={isReversed} />}

        {children}

        {isLoading && <StyledLoading active isReversed={isReversed} />}
      </Wrapper>
    );
  }
);

/**
 * `Button` display name.
 */

Button.displayName = 'Button';

/**
 * Export `Button` component.
 */

export default Button;
