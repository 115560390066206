
/**
 * Module dependencies.
 */

import { MenuEntry, Shortcut } from 'src/types/menu';
import { routes } from './routes';
import contactsIcon from 'src/assets/svg/contacts.svg';
import infoIcon from 'src/assets/svg/info.svg';
import reportIcon from 'src/assets/svg/report.svg';

/**
 * Export `menu` constant.
 */

export const menu: Array<MenuEntry> = [{
  label: 'common:menu.dashboard.label',
  to: routes.home
}, {
  label: 'common:menu.reports.label',
  to: routes.reports
}, {
  label: 'common:menu.contacts.label',
  to: routes.contacts.index
}, {
  label: 'common:menu.info.label',
  submenu: [{
    label: 'common:menu.info.helpCenter'
  }, {
    href: process.env.NEXT_PUBLIC_API_DOCS_URL,
    label: 'common:menu.info.docs'
  }]
}, {
  label: 'common:menu.profile.label',
  submenu: [{
    label: 'common:menu.profile.data',
    to: routes.account.profile
  }, {
    label: 'common:menu.profile.credits',
    to: routes.account.credits
  }, {
    label: 'common:menu.profile.billing',
    to: routes.account.billing
  }, {
    label: 'common:menu.profile.password',
    to: routes.account.changePassword
  }, {
    label: 'common:menu.profile.security',
    to: routes.account.security
  }, {
    label: 'common:menu.profile.account'
  }],
  to: routes.account.profile
}, {
  label: 'common:menu.terms.label',
  submenu: [{
    href: process.env.NEXT_PUBLIC_TERMS_URL,
    label: 'common:menu.terms.privacyPolicy'
  }, {
    href: process.env.NEXT_PUBLIC_USAGE_URL,
    label: 'common:menu.terms.usagePolicy'
  }]
}];

/**
 * Export `shortcuts` constant.
 */

export const shortcuts: Array<Shortcut> = [{
  icon: reportIcon,
  label: 'common:menu.reports.label',
  to: routes.reports
}, {
  icon: contactsIcon,
  label: 'common:menu.contacts.label',
  to: routes.contacts.index
}, {
  icon: infoIcon,
  label: 'common:menu.info.label',
  submenu: [{
    href: process.env.NEXT_PUBLIC_API_DOCS_URL,
    label: 'common:menu.info.docs'
  }, {
    label: 'common:menu.terms.label',
    submenu: [{
      label: 'common:menu.terms.privacyPolicy'
    }, {
      label: 'common:menu.terms.usagePolicy'
    }]
  }]
}];
