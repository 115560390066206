
/**
 * Module dependencies.
 */

import { CSSTransition } from 'react-transition-group';
import { ReactNode, useRef } from 'react';
import { createReactPortal } from '@untile/react-components/dist/utils';
import { useBodyScroll } from '@untile/react-components/dist/hooks';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode;
  isOpen: boolean;
};

/**
 * `ModalPortal` component.
 */

const ModalPortal = ({ children, isOpen }: Props) => {
  const ref = useRef(null);

  useBodyScroll({ off: isOpen });

  return createReactPortal((
    <CSSTransition
      in={isOpen}
      mountOnEnter
      nodeRef={ref}
      timeout={300}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  ), 'modal-root');
};

/**
 * Export `ModalPortal` component.
 */

export default ModalPortal;
