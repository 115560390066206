
/**
 * Module dependencies.
 */

import { Type } from 'src/components/core/typography';
import { color, units } from '@untile/react-components/dist/styles';
import { ifProp, theme } from 'styled-tools';
import { menu } from 'src/core/menu';
import { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import Dropdown from 'src/components/core/dropdown';
import IconButton from 'src/components/core/buttons/icon-button';
import LogoLink from 'src/components/menu/logo-link';
import SubmenuEntry from './submenu-entry';
import SubmenuProfile from 'src/components/menu/submenu-profile';
import accountIcon from 'src/assets/svg/account.svg';
import closeIcon from 'src/assets/svg/close.svg';
import menuIcon from 'src/assets/svg/menu.svg';
import styled, { ThemeContext, css } from 'styled-components';
import useBodyScroll from 'src/hooks/use-body-scroll';

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;
  z-index: ${theme('zIndex.menu')};
`;

/**
 * `Header` styled component.
 */

const Header = styled.div`
  align-items: center;
  background-color: ${color('white')};
  border-radius: ${units(3)};
  box-shadow: -${units(0.5)} ${units(1)} ${units(4)} ${units(0.5)} ${color.transparentize('black', 0.08)};
  display: flex;
  justify-content: space-between;
  left: 0;
  margin: ${units(0.5)};
  padding: 0 ${units(1)};
  position: fixed;
  right: 0;
  top: 0;
`;

/**
 * `Backdrop` styled component.
 */

const Backdrop = styled.div<{ isOpen: boolean }>`
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color ${theme('animations.defaultTransition')};
  z-index: 1;

  ${ifProp('isOpen', css`
    background-color: ${color.transparentize('black', 0.2)};
  `, css`
    background-color: transparent;
    pointer-events: none;
  `)}
`;

/**
 * `MenuWrapper` styled component.
 */

const MenuWrapper = styled.div<{ isOpen: boolean }>`
  background-color: ${color('white')};
  border-bottom-right-radius: ${units(2.5)};
  border-top-right-radius: ${units(2.5)};
  height: 100vh;
  left: 0;
  max-width: 400px;
  overflow: scroll;
  padding: ${units(1)} ${units(2)};
  position: fixed;
  top: 0;
  transition: transform ${theme('animations.defaultTransition')};
  width: 80%;
  z-index: 2;

  ${ifProp('isOpen', css`
    transform: translateX(0);
  `, css`
    transform: translateX(-100%);
  `)}
`;

/**
 * `MenuContent` styled component.
 */

const MenuContent = styled.div`
  display: grid;
  grid-row-gap: ${units(3)};
  padding: ${units(1)} 0;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  padding: ${units(2)};
`;

/**
 * `MenuMobile` component.
 */

const MenuMobile = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const themeValue = useContext(ThemeContext);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setIsOpen(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events]);

  useBodyScroll({ off: isOpen });

  return (
    <Wrapper>
      <Header>
        <IconButton
          icon={menuIcon}
          iconSize={units(3)}
          onClick={() => setIsOpen(true)}
        />

        <LogoLink isMobile />

        <Dropdown
          alignment={'right'}
          hasCaret
          renderButton={buttonProps => (
            <IconButton
              {...buttonProps}
              icon={accountIcon}
              iconSize={units(3)}
            />
          )}
          width={`${theme('dimensions.submenuProfileWidthMobile')({ theme: themeValue })}px`}
        >
          <SubmenuProfile />
        </Dropdown>
      </Header>

      <Backdrop
        isOpen={isOpen}
        onClick={() => setIsOpen(false)}
      />

      <MenuWrapper isOpen={isOpen}>
        <IconButton
          icon={closeIcon}
          iconSize={units(3)}
          onClick={() => setIsOpen(false)}
        />

        <MenuContent>
          <Title>
            {t('common:menu.label')}
          </Title>

          {menu.map((submenu, index: number) => (
            <SubmenuEntry
              key={index}
              {...submenu}
            />
          ))}
        </MenuContent>
      </MenuWrapper>
    </Wrapper>
  );
};

/**
 * Export `MenuMobile` component.
 */

export default MenuMobile;
