
/**
 * Module dependencies.
 */

import { ReactNode } from 'react';
import { color, media, units } from '@untile/react-components/dist/styles';
import { ifProp, switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `Appearance` type.
 */

export type Appearance = 'default' | 'error' | 'success' | 'warning';

/**
 * `Props` type.
 */

type Props = {
  appearance?: Appearance,
  children: ReactNode,
  className?: string,
  onClose?: (event: any) => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div<{
  appearance: Appearance,
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}>`
  ${theme('typography.styles.paragraph')}

  border-radius: ${units(1)};
  padding: ${units(1)} ${units(4)};
  position: relative;

  ${media.min('sm')`
    font-size: 14px;
  `}

  ${ifProp('onClick', css`
    cursor: pointer;
  `)}

  ${switchProp('appearance', {
    default: css`
      background-color: ${color('white')};
      box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
      color: ${color('black')};
    `,
    error: css`
      background-color: ${color('error')};
      box-shadow: 1px 2px 25px rgba(255, 71, 71, 0.3);
      color: ${color('white')};
    `,
    success: css`
      background-color: ${color('success')};
      box-shadow: 1px 2px 25px rgba(93, 189, 165, 0.3);
      color: ${color('white')};
    `,
    warning: css`
      background-color: ${color('warning')};
      box-shadow: 1px 2px 25px rgba(255, 197, 49, 0.2);
      color: ${color('black')};
    `
  })}
`;

/**
 * `Message` component.
 */

const Message = (props: Props) => {
  const { appearance, children, className, onClose } = props;

  return (
    <Wrapper
      appearance={appearance}
      className={className}
      onClick={onClose}
    >
      {children}
    </Wrapper>
  );
};

/**
 * Default props.
 */

Message.defaultProps = {
  appearance: 'default'
};

/**
 * Export `Message` component.
 */

export default Message;
