
/**
 * Export `routes`.
 */

export const routes = {
  account: {
    billing: '/account/billing',
    billingEdit: '/account/billing/edit',
    billingEditVat: '/account/billing/vat-number/edit',
    billingHistory: '/account/billing/history',
    changePassword: '/account/change-password',
    credits: '/account/credits',
    profile: '/account/profile',
    profileEdit: '/account/profile/edit',
    security: '/account/security'
  },
  contacts: {
    index: '/contacts',
    listDetails: '/contacts/lists/:id',
    lists: '/contacts/lists'
  },
  home: '/',
  messages: '/messages',
  recoverPassword: '/password/recover',
  recoverPasswordSuccess: '/password/recover/success',
  reports: '/reports',
  resetPassword: '/password/reset',
  signIn: '/sign-in',
  signUp: '/sign-up'
};

/**
 * Export `authenticatedRoutes`.
 */

export const authenticatedRoutes = [
  routes.account.billing,
  routes.account.billingEdit,
  routes.account.billingEditVat,
  routes.account.billingHistory,
  routes.account.changePassword,
  routes.account.credits,
  routes.account.profile,
  routes.account.profileEdit,
  routes.account.security,
  routes.contacts.index,
  routes.contacts.listDetails,
  routes.contacts.lists,
  routes.home,
  routes.reports,
  routes.messages
];
