
/**
 * Palette.
 */

const palette = {
  black: '#000000',
  blue: '#1818ff',
  blue100: '#e0f9ff',
  blueDenim: '#0a54b6',
  green: '#5dbda5',
  green100: '#e0ffe3',
  grey50: '#f5f5f5',
  grey100: '#e7e7e7',
  grey200: '#dfdfdf',
  grey300: '#cbcbcb',
  grey400: '#bcbcbc',
  grey500: '#b0b0b0',
  grey600: '#979797',
  grey700: '#7d7d7d',
  grey800: '#545454',
  grey900: '#2f2f2f',
  pink: '#ffd3d3',
  red: '#ff4747',
  redBright: '#ff0000',
  white: '#ffffff',
  yellow100: '#fffCf5',
  yellow200: '#fff6e0',
  yellow300: '#ffe5a3',
  yellow400: '#ffd66c',
  yellow500: '#ffc531',
  yellow600: '#e4a400'
};

/**
 * Export `colors`.
 */

export const colors = {
  ...palette,
  error: palette.red,
  primary: palette.yellow500,
  required: palette.redBright,
  secondary: palette.black,
  success: palette.green,
  warning: palette.yellow500
};
