
/**
 * Module dependencies.
 */

import { Credit } from 'src/types/credits';
import { Sort, Token } from 'src/types/api';
import {
  getApiEndpoint,
  handleRequestError,
  setAuthorizationHeader
} from 'src/core/utils/requests';

import request from 'src/core/api-config/axios-instance';

/**
 * Export `GetCreditsOptions` type.
 */

export type Params = {
  sort?: Sort<Pick<Credit, 'expiryAt'>>
};

/**
 * `Props` type.
 */

type Props = {
  params?: Params,
  ssr?: boolean,
  token: Token
};

/**
 * Export `getCredits`.
 */

export async function getCredits({ params, ssr, token }: Props) {
  const endpoint = getApiEndpoint('getCredits');

  try {
    const data = await request.get(endpoint, {
      headers: setAuthorizationHeader(token),
      params
    });

    return data?.data?.data;
  } catch (error) {
    if (!ssr) {
      throw handleRequestError(error);
    }
  }
}
