/**
 * Module dependencies.
 */

import { Box } from '@untile/react-components';
import { color, media, units } from '@untile/react-components/dist/styles';
import { ifProp, prop, switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * `TableProps` type.
 */

type TableProps = {
  fixed?: boolean;
};

/**
 * Export `Table` styled component.
 */

export const Table = styled.div.attrs(() => ({ role: 'table' }))<TableProps>`
  display: table;
  width: 100%;

  ${ifProp(
    'fixed',
    css`
      table-layout: fixed;
    `
  )}
`;

/**
 * `RowGroupProps` type.
 */

type RowGroupProps = {
  [key: string]: any;
  type?: 'head' | 'body' | 'foot';
};

/**
 * Export `RowGroup` styled component.
 */

export const RowGroup = styled.div.attrs(({ type }: RowGroupProps) => ({
  role: 'rowgroup',
  type: type ?? 'body'
}))<RowGroupProps>`
  ${switchProp('type', {
    body: css`
      display: table-row-group;
    `,
    foot: css`
      display: table-footer-group;
    `,
    head: css`
      display: table-header-group;
      margin-bottom: ${units(2)};
    `
  })}
`;

/**
 * `RowProps` type.
 */

type RowProps = {
  rowIndex?: number;
};

/**
 * Export `Row` styled components.
 */

export const Row = styled.div.attrs(({ rowIndex }: RowProps) => ({
  'aria-rowindex': rowIndex,
  role: 'row'
}))<RowProps>`
  display: table-row;

  ${ifProp(
    ({ rowIndex }) => rowIndex,
    css`
      appearance: none;
      background-color: ${color('white')};
      border: 0;
      color: inherit;
      outline: none;
      padding: 0;
      text-decoration: inherit;
      transition: background-color ${theme('animations.defaultTransition')};

      &:focus,
      &:hover {
        background-color: ${color('yellow100')};
      }
    `
  )}
`;

/**
 * `CellProps` type.
 */

type CellProps = {
  [key: string]: any;
  align?: 'left' | 'center' | 'right';
  head?: boolean;
};

/**
 * Export `Cell` styled component.
 */

export const Cell = styled(Box).attrs(({ head }: CellProps) => ({
  role: head ? 'columnheader' : 'cell'
}))<CellProps>`
  ${theme('typography.styles.small')}

  display: table-cell;
  padding: ${units(1)};
  text-align: ${prop('align', 'left')};
  vertical-align: middle;

  ${ifProp(
    'head',
    css`
      color: ${color('grey600')};
      font-weight: 700;
    `,
    css`
      border-bottom: 1px solid ${color('grey50')};
      color: ${color('black')};
      font-size: 14px;
    `
  )}
`;

/**
 * Export `TableEmptyStateWrapper` styled component.
 */

export const TableEmptyStateWrapper = styled.div`
  ${theme('typography.styles.small')}

  background-color: ${color('yellow100')};
  border-radius: ${units(1.5)};
  color: ${color('grey600')};
  line-height: 18px;
  min-height: ${units(11)};
  padding: ${units(3)};
  position: relative;
  text-align: center;

  ${media.min('md')`
    border-radius: ${units(2.5)};
    padding: 35px ${units(3)};
  `}
`;
