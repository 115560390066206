/**
 * Module dependencies.
 */

import { color } from '@untile/react-components/dist/styles';
import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';

/**
 * Export `GlobalStyle` component.
 */

export default createGlobalStyle`
  body {
    ${theme('typography.styles.p')}

    color: ${color('secondary')};
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;
