
/**
 * Module dependencies.
 */

import { Time } from 'src/types/time';
import { format } from 'date-fns';
import { pt } from 'date-fns/locale';
import isDate from 'lodash/isDate';

/**
 * Export `formatDate`.
 */

export function formatDate(date: Date, pattern: string): string {
  if (!date || !isDate(date)) {
    return '-';
  }

  return format(date, pattern, { locale: pt });
}

/**
 * `MergeDateTimeProps` type.
 */

type MergeDateTimeProps = {
  date: Date | null,
  inclusive?: boolean,
  time: Time | null
}

/**
 * Export `mergeDateTime` component.
 */

export const mergeDateTime = ({ date, inclusive, time }: MergeDateTimeProps) => {
  if (time === null) {
    return date;
  }

  if (date === null) {
    return null;
  }

  const [hours, minutes] = time.split(':');
  const dateTime = new Date(date.getTime());

  if (inclusive) {
    dateTime.setDate(dateTime.getDate() - 1);
  }

  dateTime.setHours(Number(hours));
  dateTime.setMinutes(Number(minutes));

  return dateTime;
};

/**
 * Export `clearTimeFromDate` component.
 */

export const clearTimeFromDate = (date: Date | null) => {
  if (date === null) {
    return null;
  }

  const clearDate = new Date(date.getTime());

  clearDate.setHours(0);
  clearDate.setMinutes(0);

  return clearDate;
};

/**
 * Export `formatCurrency` component.
 */

export const formatCurrency = (amount: number, decimalPlaces?: number): string => {
  return new Intl.NumberFormat('pt-PT', {
    currency: 'EUR',
    style: 'currency',
    ...decimalPlaces && { maximumSignificantDigits: decimalPlaces }
  }).format(amount);
};

/**
 * Export `highlightOccurrences` component.
 */

export const highlightOccurrences = (haystack: string, needle: string) => {
  const index = haystack.toLowerCase().indexOf(needle.toLowerCase());

  if (index === -1) {
    return haystack;
  }

  return `${haystack.slice(0, index)}<b>${haystack.slice(index, index + needle.length)}</b>${haystack.slice(index + needle.length)}`;
};
