
/**
 * Module dependencies.
 */

import { keyframes } from 'styled-components';

/**
 * Caret parameters.
 */

const angle = '45deg';
const midScale = 1 / Math.sqrt(2);
const gap = '28%';

/**
 * `caretBeforeOpen` keyframes.
 */

const caretBeforeOpen = keyframes`
  100% {
    transform: translateX(${gap}) rotate(${angle});
  }

  50% {
    transform: translateX(${gap}) scaleX(${midScale});
  }

  0% {
    transform: translateX(${gap}) rotate(-${angle});
  }
`;

/**
 * `caretAfterOpen` keyframes.
 */

const caretAfterOpen = keyframes`
  100% {
    transform: translateX(-${gap}) rotate(-${angle});
  }

  50% {
    transform: translateX(-${gap}) scaleX(${midScale});
  }

  0% {
    transform: translateX(-${gap}) rotate(${angle});
  }
`;

/**
 * `caretBeforeClose` keyframes.
 */

const caretBeforeClose = keyframes`
  0% {
    transform: translateX(${gap}) rotate(${angle});
  }

  50% {
    transform: translateX(${gap}) scaleX(${midScale});
  }

  100% {
    transform: translateX(${gap}) rotate(-${angle});
  }
`;

/**
 * `caretAfterClose` keyframes.
 */

const caretAfterClose = keyframes`
  0% {
    transform: translateX(-${gap}) rotate(-${angle});
  }

  50% {
    transform: translateX(-${gap}) scaleX(${midScale});
  }

  100% {
    transform: translateX(-${gap}) rotate(${angle});
  }
`;

/**
 * `fadeOut` keyframes.
 */

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

/**
 * `fadeInUp` keyframes.
 */

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(80px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

/**
 * `fadeOutDown` keyframes.
 */

const fadeOutDown = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(80px);
  }
`;

/**
 * `slideLeft` keyframes.
 */

const slideLeft = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;

/**
 * `slideInRight` keyframes.
 */

const slideRight = keyframes`
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
`;

/**
 * Export `keyframes`.
 */

export default {
  caretAfterClose,
  caretAfterOpen,
  caretBeforeClose,
  caretBeforeOpen,
  fadeInUp,
  fadeOut,
  fadeOutDown,
  slideLeft,
  slideRight
};
