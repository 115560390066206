/**
 * Module dependencies.
 */

import { Crisp } from 'crisp-sdk-web';
import { UserSession } from 'src/types/session';
import { useEffect } from 'react';
import { useUserCredits } from './use-user-credits';

/**
 * `isProduction` constant.
 */

const isProduction = process.env.NEXT_PUBLIC_IS_PRODUCTION === 'true';

/**
 * Events.
 */

const events = {
  signIn: 'signin',
  smsSent: 'message-sent-app'
};

/**
 * `Event` type.
 */

type Event = keyof typeof events;

/**
 * Export `sendCrispEvent` hook.
 */

export function sendCrispEvent(event: Event) {
  if (isProduction) {
    Crisp?.session?.pushEvent(events[event]);
  }
}

/**
 * Export `useCrispUserData` hook.
 */

export function useCrispUserData({
  isAuthenticated,
  user
}: {
  isAuthenticated: boolean;
  user: UserSession;
}) {
  const { credits } = useUserCredits();

  useEffect(() => {
    if (!isAuthenticated || !user || !isProduction) {
      return;
    }

    Crisp?.user?.setEmail(user.email);
    Crisp?.user?.setNickname(`${user.firstName} ${user.lastName}`);
    Crisp.user.setPhone(`${user.phoneCode}${user.phoneNumber}`);
    Crisp?.session?.setData({
      plan: credits?.totalBought > 0 ? 'paid' : 'free',
      registration_date: new Date(user.createdAt).toISOString(), // eslint-disable-line id-match
      remaing_credits: credits?.total, // eslint-disable-line id-match
      user_id: user.id // eslint-disable-line id-match
    });
  }, [credits, isAuthenticated, user]);
}
