
/**
 * Module dependencies.
 */

import { Context, ReactNode, createContext, useContext } from 'react';
import { Options } from 'src/components/core/snackbar';
import noop from 'lodash/noop';

/**
 * Export `SnackbarContext` context.
 */

export const SnackbarContext: Context<{
  removeMessage: (id: number) => void,
  showMessage: (content: ReactNode, options?: Options) => void
}> = createContext({
  removeMessage: noop,
  showMessage: noop
});

/**
 * Export `useSnackbar` hook.
 */

export const useSnackbar = () => useContext(SnackbarContext);
