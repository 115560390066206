
/**
 * Module dependencies.
 */

import { routes } from 'src/core/routes';
import { units } from '@untile/react-components/dist/styles';
import RouterLink from 'src/components/core/links/router-link';
import Svg from 'src/components/core/svg';
import brandingIcon from 'src/assets/svg/branding.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  isMobile?: boolean
};

/**
 * `Link` styled component.
 */

const Link = styled(RouterLink)`
  line-height: 0;
  outline: none;
  text-decoration: none;
`;

/**
 * `LogoLink` component.
 */

const LogoLink = ({ isMobile }: Props) => (
  <Link href={routes.home}>
    <Svg
      icon={brandingIcon}
      size={units(isMobile ? 6 : 7)}
    />
  </Link>
);

/**
 * Export `LogoLink` component.
 */

export default LogoLink;
