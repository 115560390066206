
/**
 * Module dependencies.
 */

import { LayoutDefaultContent, LayoutDefaultGrid } from './default';
import { ReactNode } from 'react';
import { getUserName } from 'src/core/utils/user';
import { ifNotProp, theme } from 'styled-tools';
import { media, units, useBreakpoint } from '@untile/react-components';
import { profileMenu } from 'src/core/profile-menu';
import { useSession } from 'src/context/session/context';
import { useTranslation } from 'next-i18next';
import Header from 'src/components/header';
import MenuProfile from 'src/components/menu/desktop/menu-profile';
import PageTransition from 'src/components/page-transition';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  children: ReactNode,
  isFullWidth?: boolean
};

/**
 * `Grid` styled component.
 */

const Grid = styled.div<Pick<Props, 'isFullWidth'>>`
  padding-bottom: ${units(3)};

  ${media.min('md')`
    align-items: flex-start;
    display: grid;
    grid-column-gap: ${units(3)};
    grid-template-areas: 'accountMenu accountContent';
    grid-template-columns: max-content 1fr;

    ${ifNotProp('isFullWidth', css`
      max-width: ${units(110)};
    `)}
  `}
`;

/**
 * `StyledHeader` styled component.
 */

const StyledHeader = styled(Header)`
  ${media.min('md')`
    position: relative;
    z-index: ${theme('zIndex.menu')};
  `}
`;

/**
 * `StyledMenuProfile` styled component.
 */

const StyledMenuProfile = styled(MenuProfile)`
  z-index: ${theme('zIndex.menu')};

  ${media.min('md')`
    grid-area: accountMenu;
    width: 100%;
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  ${media.min('md')`
    grid-area: accountContent;
  `}
`;

/**
 * `AccountLayout` component.
 */

const AccountLayout = ({ children, isFullWidth }: Props) => {
  const { t } = useTranslation();
  const { user } = useSession();
  const isDesktop = useBreakpoint('md', 'min');

  return (
    <LayoutDefaultGrid>
      <LayoutDefaultContent>
        <StyledHeader
          title={t('account:labels.welcome', {
            name: getUserName(user)
          })}
        />

        <Grid isFullWidth={isFullWidth}>
          {isDesktop && <StyledMenuProfile entries={profileMenu} />}

          <PageTransition>
            <Content>
              {children}
            </Content>
          </PageTransition>
        </Grid>
      </LayoutDefaultContent>
    </LayoutDefaultGrid>
  );
};

/**
 * Export `AccountLayout` component.
 */

export default AccountLayout;
