
/**
 * Export `cookiesList`.
 */

export const cookiesList = {
  welcome: 'welcome-tling'
};

/**
 * Export `pageSizes`.
 */

export const pageSizes = {
  contacts: 20,
  list: 10
};
