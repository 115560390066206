/**
 * Module dependencies.
 */

import { ifProp, theme } from 'styled-tools';
import { units } from '@untile/react-components/dist/styles';
import { useDetectOutsideClick } from 'src/hooks/use-detect-outside-click';
import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

/**
 * `ButtonProps` type.
 */

type ButtonProps = {
  'aria-expanded': boolean;
  'aria-haspopup': boolean;
  isActive?: boolean;
  onClick: () => void;
  role: string;
  tabIndex: number;
};

/**
 * `Props` type.
 */

type Props = {
  className?: string;
  renderButton: (props: ButtonProps) => JSX.Element;
  renderChildren: ({ onClose }: { onClose: () => void }) => JSX.Element;
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
`;

/**
 * `Content` styled component.
 */

const Content = styled.div<{ isActive: boolean }>`
  left: 0;
  opacity: 0;
  position: absolute;
  top: -${units(3.5)};
  transform: translateY(${units(3)});
  transition: ${theme('animations.defaultTransition')};
  transition-property: opacity, transform, visibility;
  visibility: hidden;
  width: max-content;
  z-index: -1;

  ${ifProp(
    'isActive',
    css`
      opacity: 1;
      transform: translateY(0);
      visibility: visible;
    `
  )}
`;

/**
 * `Dropright` component.
 */

const Dropright = (props: Props) => {
  const { className, renderButton, renderChildren } = props;
  const ref = useRef<HTMLDivElement>();
  const [active, setActive] = useState<boolean>(false);

  useDetectOutsideClick(ref, () => setActive(false));

  return (
    <Wrapper className={className}>
      {renderButton({
        'aria-expanded': active,
        'aria-haspopup': true,
        isActive: active,
        onClick: () => setActive(!active),
        role: 'button',
        tabIndex: 0
      })}

      <Content isActive={active} ref={ref}>
        {renderChildren({ onClose: () => setActive(false) })}
      </Content>
    </Wrapper>
  );
};

/**
 * Export `Dropright` component.
 */

export default Dropright;
