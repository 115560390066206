/**
 * Module dependencies.
 */

import { Entry, SubEntry, SubEntryWrapper } from 'src/components/menu/entries';

import { MenuEntry } from 'src/types/menu';
import { units } from '@untile/react-components';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

/**
 * `StyledSubEntryWrapper` styled component.
 */

const StyledSubEntryWrapper = styled(SubEntryWrapper)`
  padding: ${units(0.5)} 0;
`;

/**
 * `SubmenuEntry` component.
 */

const SubmenuEntry = ({ label, submenu, to }: MenuEntry) => {
  const { t } = useTranslation();
  const router = useRouter();

  const isActiveEntry = (to: string) => {
    if (to === '/') {
      return router.asPath === '/';
    }

    return router.asPath.startsWith(to);
  };

  const isActiveSubEntry = (to: string) => router.asPath === to;

  return (
    <div>
      <Entry isActive={isActiveEntry(to)} key={to} to={to}>
        {t(label)}
      </Entry>

      {!isEmpty(submenu) && (
        <StyledSubEntryWrapper>
          {submenu.map(({ label, ...subRest }, index: number) => (
            <SubEntry
              {...subRest}
              isActive={isActiveSubEntry(subRest.to)}
              key={index}
            >
              {t(label)}
            </SubEntry>
          ))}
        </StyledSubEntryWrapper>
      )}
    </div>
  );
};

/**
 * Export `SubmenuEntry` component.
 */

export default SubmenuEntry;
