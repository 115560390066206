
/**
 * Export `dimensions`.
 */

export const dimensions = {
  menuPaddingHorizontal: 16,
  menuWidth: 72,
  submenuProfileWidth: 192,
  submenuProfileWidthMobile: 160
};
